import { Button, Select } from 'antd'
import React, { useState } from 'react'
import '../styles.css'

function EnquiryForm() {
    const [classStudying, setClassStudying] = useState(null);
    const [field, setField] = useState("");
    return (
        <div className='enquire-form-parent'>
            <div className='enquire-form'>
                <div className='text-md color-primary text-center font-bold mobile-title-font'>Let us help!</div>
                <div className='enquire-input-div'>
                    <div className='select-std-input'>
                        <div className='text-sm color-light-grey'>Which class are you in?</div>
                        <Select
                            placeholder=""
                            size='large'
                            style={{ width: "100%" }}
                            onChange={(value) => { setClassStudying(value) }}
                            className='no-border-select'>
                            <Option value={8}>Class X boards</Option>
                            {/* <Option value={9}>Class X appearing & passed</Option> */}
                            <Option value={10}>NEET & JEE</Option>
                        </Select>
                        <div style={{ borderBottom: "1px solid #999999", width: "100%" }}></div>
                    </div>
                    <div className='select-std-input'>
                        <div className='text-sm color-light-grey'>Choose your field</div>
                        <Select
                            placeholder=""
                            size='large'
                            style={{ width: "100%" }}
                            className='no-border-select'
                            onChange={(value) => { setField(value) }}
                        >
                            <Option value={"neet"}>NEET (Medical)</Option>
                            <Option value={"jee"}>JEE (Engineering)</Option>
                        </Select>
                        <div style={{ borderBottom: "1px solid #999999", width: "100%" }}></div>
                    </div>
                    <Button
                        size={"large"}
                        disabled={!classStudying || ((classStudying == 9 || classStudying == 10) && !field)}
                        className='banner-form-continue-btn'
                        onClick={() => {
                            if (classStudying == 8) {
                                window.location.assign('https://tutorials.ashreya.com/')
                            } /* else if (classStudying == 9 && field == "neet") {
              window.location.assign('https://academy.ashreya.com/courses/medical')
            } else if (classStudying == 9 && field == "jee") {
              window.location.assign('https://academy.ashreya.com/courses/engineering')
            }  */ else if (classStudying == 10 && field == "neet") {
                                window.location.assign('https://coaching.ashreya.com/medical-target-batch')
                            } else if (classStudying == 10 && field == "jee") {
                                window.location.assign('https://coaching.ashreya.com/engineering-target-batch')
                            }
                        }}>
                        CONTINUE
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default EnquiryForm