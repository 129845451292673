import React from 'react'
import '../styles.css'
import { handleCoachingShare } from './webCoachingBanner'

const MobileCoachingBannerCard = ({ text, amount, index, originalAmount, setShareUrl, setIsShareModalVisible }) => {
    return (
        <>
            <div className='c-c-c cursor-pointer ban-coaching-zoom' style={{ width: "fit-content" }}>
                <div className='text-20 color-primary font-bold m-t-5 line-container-font1'>{text}</div>
                <div className='banner-nav-border r-c-c' style={{ width: "100%", marginTop: 0 }}></div>
                {index == 3 || index == 4 ? <div className='color-black font-600 m-t-10' style={{ fontSize: 14 }}>[Study from home]</div> : <></>}
                <div className='text-md color-sec-black font-bold m-b-10 line-container-font1' style={{ marginTop: 8 }}>{amount}</div>
                <div className='font-bold line-container-font1 m-b-5' style={{ textDecoration: 'line-through', color: "gray", marginTop: -5, fontSize: 10 }}>{originalAmount}</div>
                <div className='r-sb' style={{ width: "100%" }}>
                    <div className='r-c-c cursor-pointer' onClick={(e) => handleCoachingShare(e, index, setShareUrl, setIsShareModalVisible)}>
                        <img alt='shareIcon2' style={{ height: 20, width: 20 }} src={require("../../../assets/AshreyaHome/share-grey.png")} loading="lazy" />
                    </div>
                    <img alt='info' src={require("../../../assets/AshreyaHome/Info.png")} loading="lazy" style={{ height: 20, width: 20 }} />
                </div>
            </div>
        </>
    )
}

function MobCoachingBanner({ isShareModalVisible, setIsShareModalVisible, setShareUrl }) {
    return (
        <div className='p-h-20'>
            <div className='font-bold text-24 color-primary m-t-40'>Coaching Programmes</div>
            <div className='text-sm color-light-black m-t-20' style={{ lineHeight: 1.6 }}>The ashreya Coaching Programmes are designed to assist students who aspire to pursue careers in medicine or engineering, helping them prepare for these fields effectively.</div>
            {/* <div className='text-sm color-light-black font-bold m-t-20' style={{ lineHeight: 1.6 }}>We are providing Early Bird Discount in Capsule Course - 25% discount if admission taken on or before 15th October</div> */}
            <div className='line-mob-container'>
                <svg viewBox="0 0 261 309" fill="none" style={{ marginTop: 20, height: "430px" }}>
                    <path d="M1.90243e-05 201.977L2.17556e-05 139.494C2.21829e-05 129.718 10.2866 121.765 22.9312 121.765L103.749 121.765C116.393 121.765 126.681 129.717 126.681 139.494L126.681 177.894C126.681 186.492 135.729 193.487 146.85 193.487L187.26 193.391L187.26 195.504L146.85 195.624C134.206 195.624 123.918 187.671 123.918 177.894L123.918 139.494C123.918 130.897 114.871 123.901 103.749 123.901L22.9312 123.901C11.8107 123.901 2.76327 130.896 2.76327 139.494L2.76326 201.977C2.76326 210.575 11.8107 217.57 22.9312 217.57L94.6597 217.471L94.6597 219.613L22.9312 219.706C10.2866 219.706 1.8597e-05 211.753 1.90243e-05 201.977Z" fill="#00BDE7" />
                    <path d="M150.737 100.746L150.737 98.666L233.531 98.666C248.294 98.666 260.304 106.527 260.304 116.19L260.304 177.949C260.304 187.611 248.294 195.473 233.531 195.473L186.351 195.473L186.351 193.361L233.531 193.361C246.515 193.361 257.078 186.448 257.078 177.949L257.078 116.19C257.078 107.692 246.515 100.778 233.531 100.778L150.737 100.746Z" fill="#FF8C05" />
                    <path d="M58.3818 305.661L169.717 305.661C184.15 305.661 195.892 298.497 195.892 289.691L195.892 233.408C195.892 224.602 184.151 217.438 169.717 217.438L122.017 217.438L122.017 217.47L88.6232 217.47L88.6232 219.394L125.171 219.394L125.171 219.362L169.717 219.362C182.41 219.362 192.738 225.663 192.738 233.408L192.738 289.691C192.738 297.436 182.412 303.737 169.717 303.737L58.3818 303.737C57.6949 302.276 55.4872 301.204 52.8757 301.204C49.7176 301.204 47.1468 302.771 47.1468 304.699C47.1468 306.627 49.7162 308.195 52.8757 308.195C55.4872 308.195 57.6949 307.122 58.3818 305.662L58.3818 305.661ZM50.3007 304.699C50.3007 303.833 51.4558 303.128 52.8757 303.128C54.2957 303.128 55.4507 303.833 55.4507 304.699C55.4507 305.565 54.2957 306.27 52.8757 306.27C51.4558 306.27 50.3007 305.565 50.3007 304.699Z" fill="#FF00A8" />
                    <path d="M171.987 98.6978L94.4165 98.4548C82.4138 98.4548 72.6473 91.4715 72.6473 82.8871L72.6473 7.59758C74.9113 7.1331 76.5735 5.64012 76.5735 3.87415C76.5735 1.73848 74.1438 -4.27685e-06 71.1561 -4.40745e-06C68.1683 -4.53804e-06 65.7386 1.73753 65.7386 3.87415C65.7386 5.64012 67.4008 7.1331 69.6635 7.59758L69.6635 82.888C69.6635 92.6478 80.7662 100.589 94.4152 100.589L171.984 100.753L171.984 98.4558L171.987 98.6978ZM68.7237 3.87415C68.7237 2.9139 69.816 2.13282 71.1587 2.13282C72.5015 2.13282 73.5937 2.9139 73.5937 3.87415C73.5937 4.83439 72.5015 5.61548 71.1587 5.61548C69.816 5.61548 68.7237 4.83439 68.7237 3.87415Z" fill="#01CA39" />
                </svg>
                {/* <svg viewBox="0 0 311 224" fill="none" style={{ marginTop: 20, height: "250px" }}>
        <path d="M194.934 223.007L127.246 223.007C116.655 223.007 108.039 213.663 108.039 202.177L108.039 128.767C108.039 117.282 116.654 107.937 127.246 107.937L168.844 107.937C178.158 107.937 185.737 99.7185 185.737 89.6161L185.737 56.7111L188.027 56.71L188.051 89.6161C188.051 101.102 179.436 110.447 168.844 110.447L127.246 110.447C117.932 110.447 110.353 118.665 110.353 128.767L110.353 202.177C110.353 212.279 117.931 220.497 127.246 220.497L194.934 220.497C204.248 220.497 211.825 212.279 211.825 202.177L211.719 146.214L214.039 146.214L214.14 202.177C214.14 213.663 205.524 223.007 194.934 223.007Z" fill="#00BDE7" fill-opacity="0.4" />
        <path d="M85.2302 67.8993L82.9687 67.8993L82.9687 20.5434C82.9688 9.21574 91.5158 0.000488635 102.023 0.000489069L169.174 0.000491844C179.68 0.000492279 188.228 9.21575 188.228 20.5434L188.228 56.7443L185.932 56.7443L185.932 20.5434C185.932 10.5811 178.415 1.8496 169.174 1.8496L102.023 1.8496C92.7832 1.8496 85.2647 10.5811 85.2647 20.5434L85.2302 67.8993Z" fill="#FF8C05" fill-opacity="0.4" />
        <path d="M307.26 168.43L307.26 86.5641C307.26 75.9518 299.5 67.3174 289.96 67.3174L228.988 67.3174C219.448 67.3174 211.687 75.9507 211.687 86.5641L211.687 121.639L211.722 121.639L211.722 146.193L213.806 146.193L213.806 119.319L213.772 119.319L213.772 86.5641C213.772 77.2309 220.598 69.0363 228.988 69.0363L289.96 69.0363C298.35 69.0363 305.176 77.2299 305.176 86.5641L305.176 168.43C303.593 168.935 302.431 170.559 302.431 172.479C302.431 174.801 304.13 176.692 306.218 176.692C308.306 176.692 310.005 174.802 310.005 172.479C310.005 170.559 308.843 168.935 307.261 168.43L307.26 168.43ZM306.218 174.372C305.279 174.372 304.516 173.523 304.516 172.479C304.516 171.435 305.279 170.586 306.218 170.586C307.157 170.586 307.92 171.435 307.92 172.479C307.92 173.523 307.157 174.372 306.218 174.372Z" fill="#FF00A8" fill-opacity="0.4" />
        <path d="M83.0548 67.3501L82.7916 140.67C82.7916 152.016 75.2265 162.019 65.927 162.019L8.23051 162.019C7.72733 159.879 6.10998 157.536 4.19689 157.536C1.88331 157.536 -4.27509e-06 159.833 -4.40563e-06 162.657C-4.53618e-06 165.481 1.88228 167.777 4.19689 167.777C6.10998 167.777 7.72733 166.206 8.23051 164.067L65.928 164.067C76.5008 164.067 85.1031 153.573 85.1031 140.672L85.2807 67.3526L82.7926 67.3526L83.0548 67.3501ZM4.19689 164.956C3.15665 164.956 2.31049 163.923 2.31049 162.654C2.31049 161.385 3.15665 160.352 4.19689 160.352C5.23713 160.352 6.08328 161.385 6.08328 162.654C6.08328 163.923 5.23713 164.956 4.19689 164.956Z" fill="#008525" fill-opacity="0.4" />
      </svg> */}
                <div style={{ position: "absolute", top: 185, left: 210, width: "fit-content" }} onClick={() => { window.location.assign('https://ashreya.aulas.in/') }}>
                    <MobileCoachingBannerCard
                        text={"Target NEET 2025"}
                        amount={"₹ 65,000"}
                        originalAmount={"₹ 80,000"}
                        index={1}
                        isShareModalVisible={isShareModalVisible}
                        setIsShareModalVisible={setIsShareModalVisible}
                        setShareUrl={setShareUrl} />
                </div>
                <div style={{ position: "absolute", top: 335, left: 120, width: "fit-content" }} onClick={() => { window.location.assign('https://ashreya.aulas.in/') }}>
                    <MobileCoachingBannerCard
                        text={"Target JEE 2025"}
                        amount={"₹ 65,000"}
                        originalAmount={"₹ 80,000"}
                        index={2}
                        isShareModalVisible={isShareModalVisible}
                        setIsShareModalVisible={setIsShareModalVisible}
                        setShareUrl={setShareUrl} />
                </div>
                <div style={{ position: "absolute", top: 20, left: 130, width: "fit-content" }} onClick={() => { window.location.assign('https://ashreya.aulas.in/') }}>
                    <MobileCoachingBannerCard
                        text={"Target NEET 2025"}
                        amount={"₹ 25,000"}
                        originalAmount={"₹ 50,000"}
                        index={3}
                        isShareModalVisible={isShareModalVisible}
                        setIsShareModalVisible={setIsShareModalVisible}
                        setShareUrl={setShareUrl} />
                </div>
                <div style={{ position: "absolute", top: 193, left: 30, width: "fit-content" }} onClick={() => { window.location.assign('https://ashreya.aulas.in/') }}>
                    <MobileCoachingBannerCard
                        text={"Target JEE 2025"}
                        amount={"₹ 25,000"}
                        originalAmount={"₹ 50,000"}
                        index={4}
                        isShareModalVisible={isShareModalVisible}
                        setIsShareModalVisible={setIsShareModalVisible}
                        setShareUrl={setShareUrl} />
                </div>
            </div>
            {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}><img src={require("../../assets/discountfifteenPC.svg").default} style={{ width: 200 }} alt='earlyBirdImage2' loading='lazy'/></div> */}
            <div className='text-xs font-bold m-t-10' style={{ color: "#B81B1B", textAlign: "right" }}>ADMISSIONS OPEN</div>
        </div>
    )
}

export default MobCoachingBanner