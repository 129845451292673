import React from 'react'
import '../styles.css'
import Carousel from 'react-multi-carousel';
const testimonialPerson1 = require("../../../assets/AshreyaHome/person_1.jpg");
const testimonialPerson2 = require("../../../assets/AshreyaHome/person_2.jpg");
const testimonialPerson3 = require("../../../assets/AshreyaHome/person_3.jpg");
const testimonialPerson4 = require("../../../assets/AshreyaHome/person_4.jpg");
const testimonialPerson5 = require("../../../assets/AshreyaHome/person_5.jpg");
const testimonialPerson6 = require("../../../assets/AshreyaHome/person_6.jpg");

const ButtonGroupTestimonial = ({ next, previous, goToSlide, ...rest }) => {
    const {
        carouselState: { currentSlide, deviceType }
    } = rest;
    let slideIndex = 5;
    if (deviceType === "mobile") {
        slideIndex = 0;
    }
    return (
        <div className="r-c-c m-t-10">
            {[0, 1, 2, 3, 4, 5].map((indicator) => (
                <div
                    key={indicator}
                    className="carousel-indicator m-r-10"
                    onClick={() => goToSlide(indicator)}
                    style={{
                        background: currentSlide === indicator ? "#FFA91F" : "#FFF6E9"
                    }}
                ></div>
            ))}
        </div>
    );
};

export const responsiveTestimonial = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 767 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 767, min: 0 },
        items: 1
    }
};

const StarRating = () => {
    const totalStars = 5;
    const starColor = '#F8B84E';
    return (
        <div>
            {[...Array(totalStars)].map((_, index) => (
                <span
                    key={index}
                    className="star"
                    style={{
                        color: starColor,
                        cursor: 'pointer',
                    }}
                >
                    ★
                </span>
            ))}
        </div>
    );
};

function TestimonialsSection() {
    const testimonial_1 = `Boosted my confidence and got personalized attention at ashreya. `;
    const testimonial_2 = `Great teachers and comfortable hostels.`;
    const testimonial_3 = `Teachers at ashreya care like parents.`;
    const testimonial_4 = `ashreya Academy: A game-changer in NEET preparation!`;
    const testimonial_5 = `ashreya: Your dream-to-reality transformation partner!`;
    const testimonial_6 = `Best Institute for JEE/NEET, friendly teachers.`;
    const testimonialsData = [
        {
            text: testimonial_1,
            name: "Shashwata Singh",
            coaching: "Target 2023-2024",
            image: testimonialPerson1
        },
        {
            text: testimonial_2,
            name: "Lalchan Ali",
            coaching: "Integrated 2023-25",
            image: testimonialPerson2
        },
        {
            text: testimonial_3,
            name: "Mokibul Islam",
            coaching: "NEET Dropper 2023-24",
            image: testimonialPerson3
        },
        {
            text: testimonial_4,
            name: "Ashraful Alom",
            coaching: "NEET Dropper 2023-25",
            image: testimonialPerson4
        },
        {
            text: testimonial_5,
            name: "Kakali Baishya",
            coaching: "Target 2023-2024",
            image: testimonialPerson5
        },
        {
            text: testimonial_6,
            name: "Barbee Tamuli",
            coaching: "Target 2023-2024",
            image: testimonialPerson6
        },
    ]
    const TestimonialsCard = ({ item, index }) => {
        return (
            <div className='testimonials-card-wrapper ban-coaching-zoom'>
                <div className='c-c-c new-testimonials-card position-relative m-b-30'>
                    <img alt="quotes" loading='lazy' src={require("../../../assets/AshreyaHome/testimonialsQuotes.svg").default} className='quotes-img-size' />
                    <div className='m-t-10 text-sm color-light-black mob-desc-font m-b-40 r-c-c' style={{ textAlign: "center", lineHeight: 1.6, height: 60 }}>{item?.text}</div>
                    <div className='position-absolute' style={{ bottom: -30 }}>
                        <img alt="web-persons" loading='lazy' src={item?.image} className='testimonials-person-img' />
                    </div>
                </div>
                <div className='c-c-c m-b-40' style={{ marginBottom: index == 3 || index == 4 || index == 5 ? "100px" : "40px" }}>
                    <div className='font-bold' style={{ color: "#000000" }}>{item?.name}</div>
                    <div className='m-t-5 m-b-5' style={{ color: "#A19DA5" }}>{item?.coaching}</div>
                    <StarRating rating={5} />
                </div>
            </div>
        )
    }
    const MobTestimonial = ({ imgProfile, text, name, coaching }) => {
        return (
            <div className='testimonials-card-wrapper'>
                <div className='c-c-c new-testimonials-card position-relative m-b-30'>
                    <img alt="mob-quotes" loading='lazy' src={require("../../../assets/AshreyaHome/testimonialsQuotes.svg").default} className='quotes-img-size' />
                    <div className='m-t-10 text-sm color-light-black mob-desc-font m-b-40' style={{ textAlign: "center", lineHeight: 1.6, height: 50 }}>{text}</div>
                    <div className='position-absolute' style={{ bottom: -30 }}>
                        <img alt="web-persons-mob" loading='lazy' src={imgProfile} className='testimonials-person-img' />
                    </div>
                </div>
                <div className='c-c-c m-b-40'>
                    <div className='font-bold' style={{ color: "#000000" }}>{name}</div>
                    <div className='m-t-5 m-b-5' style={{ color: "#A19DA5" }}>{coaching}</div>
                    <StarRating rating={5} />
                </div>
            </div>
        );
    };
    return (
        <div>
            <div className='display-flex flex-wrap r-sb p-h-120 testimonials-web-parent' style={{ width: "100%" }}>
                {testimonialsData?.map((item, index) => (
                    <TestimonialsCard item={item} index={index} />
                ))}
            </div>
            <div className='mobile-testimonial-parent p-h-20 m-b-10'>
                <Carousel
                    responsive={responsiveTestimonial}
                    arrows={false}
                    renderButtonGroupOutside={true}
                    customButtonGroup={<ButtonGroupTestimonial />}
                >
                    <MobTestimonial
                        text={testimonial_1}
                        name={testimonialsData[0]?.name}
                        coaching={testimonialsData[0]?.coaching}
                        imgProfile={testimonialsData[0]?.image}
                    />
                    <MobTestimonial
                        text={testimonial_2}
                        name={testimonialsData[1]?.name}
                        coaching={testimonialsData[1]?.coaching}
                        imgProfile={testimonialsData[1]?.image}
                    />
                    <MobTestimonial
                        text={testimonial_3}
                        name={testimonialsData[2]?.name}
                        coaching={testimonialsData[2]?.coaching}
                        imgProfile={testimonialsData[2]?.image}
                    />
                    <MobTestimonial
                        text={testimonial_4}
                        name={testimonialsData[3]?.name}
                        coaching={testimonialsData[3]?.coaching}
                        imgProfile={testimonialsData[3]?.image}
                    />
                    <MobTestimonial
                        text={testimonial_5}
                        name={testimonialsData[4]?.name}
                        coaching={testimonialsData[4]?.coaching}
                        imgProfile={testimonialsData[4]?.image}
                    />
                    <MobTestimonial
                        text={testimonial_6}
                        name={testimonialsData[5]?.name}
                        coaching={testimonialsData[5]?.coaching}
                        imgProfile={testimonialsData[5]?.image}
                    />
                </Carousel>
            </div>
        </div>
    )
}

export default TestimonialsSection