import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import "./styles.css"
import { AlignRightOutlined } from "@ant-design/icons";
import { Button } from 'antd';
import { setSideBar, setSubDomainHovered } from '../../action/ashreya';
import downArrow from '../../assets/TopBar/primaryDownArrow.svg'
import AshreyaDigitalModal from '../../pages/ashreyaDigitalModal';

function TopBar({ setSideBar, setSubDomainHovered, subDomainHovered }) {
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);
    const [isDigitalModalVisible, setIsDigitalModalVisible] = useState(false);
    const [subDomainNo, setSubDomainNo] = useState(0)
    const [menuHovered, setMenuHovered] = useState(false);
    const [menuNo, setMenuNo] = useState(0);
    const [tutorialsHomeHovered, setTutHomeHovered] = useState(false);
    const [tutorialsResHovered, setTutorialsResHovered] = useState(false);
    const [academyHomeHovered, setacademyHomeHovered] = useState(false);
    const [academyCourHovered, setacademyCourHovered] = useState(false);
    const [academyResHovered, setacademyResHovered] = useState(false);
    const [academyTestHovered, setacademyTestHovered] = useState(false);
    const [coachingMedHovered, setCoachingMedHovered] = useState(false);
    const [coachingEnggHovered, setCoachingEnggHovered] = useState(false);

    useEffect(() => {
        setSubDomainHovered(false)
    }, [])
    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 100);
            setPrevScrollPos(currentScrollPos);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    return (
        <div className={`top-bar ${visible ? 'visible' : 'hidden'}`} style={{ animation: visible ? "header 1.3s ease" : "" }}>
            <div className="r-sb r-c-c p-h-120 parent-header">
                <div
                    className="r-ac header-image"
                >
                    <img
                        className='ashreya-img-width'
                        src={require("../../assets/ashreya-primary-logo.svg").default}
                        alt="ashreya"
                        loading="lazy"
                    />
                </div>
                <div className="flex-1 r-sb flex-wrap">
                    <Link to="/">
                        <div className='cursor-pointer'>
                            <div
                                className="color-primary r-c-c text-xsm top-bar-text-wrap font-bold"
                            >
                                HOME
                            </div>
                            <div className="header-sub-nav-border" style={{ marginTop: -18 }}></div>
                        </div>
                    </Link>
                    <div className='cursor-pointer sub-domain-anime'
                        onClick={(e) => { window.location.assign('https://tutorials.ashreya.com/') }}
                        onMouseMove={(e) => { setSubDomainHovered(true); setSubDomainNo(1); setMenuNo(1); setTutHomeHovered(true); setMenuHovered(true); setTutorialsResHovered(false); setacademyCourHovered(false); setacademyResHovered(false); setacademyTestHovered(false); setCoachingMedHovered(false); setCoachingEnggHovered(false) }}
                    >
                        <div
                            className="color-primary r-c-c text-xsm top-bar-text-wrap" style={{ fontWeight: (subDomainHovered && subDomainNo == 1) ? "bold" : "normal" }}
                        >
                            TUTORIALS
                            <img alt="downArrow" src={downArrow} style={{ marginLeft: 5 }} loading="lazy"/>
                        </div>
                        <div style={{ fontSize: 14, color: "#FF9900", marginTop: -43, marginLeft: 3 }}>Class X boards</div>
                    </div>
                    {/* <div className='cursor-pointer'
                        onClick={(e) => { window.location.assign('https://academy.ashreya.com/') }}
                        onMouseMove={(e) => { setSubDomainHovered(true); setSubDomainNo(2); setMenuNo(1); setacademyHomeHovered(true); setMenuHovered(true); setTutorialsResHovered(false); setacademyCourHovered(false); setacademyResHovered(false); setacademyTestHovered(false); setCoachingMedHovered(false); setCoachingEnggHovered(false) }}>
                        <div
                            className="color-primary r-c-c text-xsm top-bar-text-wrap" style={{ fontWeight: (subDomainHovered && subDomainNo == 2) ? "bold" : "normal" }}
                        >
                            ACADEMY
                            <img alt="downArrow" src={downArrow} style={{ marginLeft: 5 }} loading="lazy"/>
                        </div>
                        <div style={{ fontSize: 14, color: "#FF9900", marginTop: -43, marginLeft: 5 }}>Class XI & XII</div>
                    </div> */}
                    <div className='cursor-pointer'
                        onClick={(e) => { window.location.assign('https://coaching.ashreya.com/') }}
                        onMouseMove={(e) => { setSubDomainHovered(true); setSubDomainNo(3); setMenuNo(1); setTutHomeHovered(true); setMenuHovered(true); setTutorialsResHovered(false); setacademyCourHovered(false); setacademyResHovered(false); setacademyTestHovered(false); setCoachingMedHovered(false); setCoachingEnggHovered(false) }}>
                        <div
                            className="color-primary r-c-c text-xsm top-bar-text-wrap" style={{ fontWeight: (subDomainHovered && subDomainNo == 3) ? "bold" : "normal" }}
                        >
                            COACHING
                            <img alt="downArrow" src={downArrow} style={{ marginLeft: 5 }} loading="lazy"/>
                        </div>
                        <div style={{ fontSize: 14, color: "#FF9900", marginTop: -43, marginLeft: 12 }}>NEET & JEE</div>
                    </div>
                    <Button className="r-c-c" style={{ backgroundColor: '#709C72', marginTop: "25px", border: "#709C72", height: 30, width: 130 }} onClick={() => { setIsDigitalModalVisible(true) }}><h style={{ color: "#FFFFFF", fontWeight: "normal" }} >ashreya digital</h></Button>
                </div>
            </div>
            {subDomainHovered ?
                <div className='r-ac sub-domain-dropdown' style={{ backgroundColor: "#FFFFFF", width: "100%", padding: subDomainHovered ? "20px 0px 20px 0px" : "0px", opacity: subDomainHovered ? 1 : 0, transition: "all ease 0.5s", boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px" }}>
                    <div style={{ width: "6%", marginLeft: "30%" }}>
                        <div className='r-ac m-b-5' style={{ height: "4vh", marginBottom: tutorialsHomeHovered || academyHomeHovered ? 15 : 5, marginTop: tutorialsHomeHovered || academyHomeHovered ? -10 : 0 }}>
                            <div key="1" className='color-primary text-xs menu-bar-text-wrap cursor-pointer'
                                onMouseMove={() => {
                                    setMenuHovered(true);
                                    setMenuNo(1);
                                    setTutorialsResHovered(false);
                                    setacademyCourHovered(false);
                                    setacademyResHovered(false);
                                    setacademyTestHovered(false);
                                    setCoachingMedHovered(false);
                                    setCoachingEnggHovered(false)
                                }}
                                onClick={() => {
                                   /*  if (subDomainNo == 1) {
                                        window.location.assign('https://tutorials.ashreya.com/')
                                    } else */ if (subDomainNo == 2) {
                                        window.location.assign('https://academy.ashreya.com/')
                                    } else if (subDomainNo == 3) {
                                        window.location.assign('https://coaching.ashreya.com/')
                                    }
                                }}
                            >
                                <span className="element" style={{ fontWeight: tutorialsHomeHovered || academyHomeHovered ? "bold" : "" }}>HOME</span>
                                {tutorialsHomeHovered || academyHomeHovered ?
                                    <div className="header-sub-nav-border" style={{ marginTop: -22 }}></div>
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                        {subDomainNo == 2 || subDomainNo == 3 ?
                            <div className='r-ac m-b-5' style={{ height: "4vh", marginBottom: academyCourHovered ? 15 : 5, marginTop: academyCourHovered ? -10 : 0 }}
                                onMouseMove={() => {
                                    if (subDomainNo == 3) {
                                        setCoachingMedHovered(true);
                                        setCoachingEnggHovered(false)
                                    }
                                    setMenuHovered(true); setMenuNo(2); setTutHomeHovered(false); setTutorialsResHovered(false); setacademyHomeHovered(false); setacademyResHovered(false); setacademyTestHovered(false)
                                }}
                                onClick={() => {
                                    if (subDomainNo == 2) {
                                        window.location.assign('https://academy.ashreya.com/courses/medical')
                                    } else if (subDomainNo == 3) {
                                        window.location.assign('https://ashreya.aulas.in/')
                                    }
                                }}>
                                <div className='color-primary text-xs menu-bar-text-wrap cursor-pointer'>
                                    <span className="element" style={{ fontWeight: academyCourHovered ? "bold" : "" }}>COURSES</span>
                                    {academyCourHovered ?
                                        <div className="header-sub-nav-border" style={{ marginTop: -22 }}></div>
                                        :
                                        <></>
                                    }
                                </div>
                            </div>
                            :
                            <></>}
                        {/* <div className='r-ac m-b-5' style={{ height: "4vh", marginBottom: tutorialsResHovered || academyResHovered ? 15 : 5, marginTop: tutorialsResHovered || academyResHovered ? -10 : 0 }}>
                            <div key="2" className='color-primary text-xs menu-bar-text-wrap cursor-pointer'
                                onMouseMove={() => { setMenuHovered(true); setMenuNo(3); setTutHomeHovered(false); setacademyHomeHovered(false); setacademyCourHovered(false); setacademyTestHovered(false); setCoachingMedHovered(false); setCoachingEnggHovered(false) }}
                                onClick={() => {
                                    if (subDomainNo == 2) {
                                        window.location.assign('https://academy.ashreya.com/resources/ncert')
                                    } else if (subDomainNo == 3) {
                                        window.location.assign('https://coaching.ashreya.com/resources/ncert')
                                    }
                                }}
                            >
                                <span className="element" style={{ fontWeight: tutorialsResHovered || academyResHovered ? "bold" : "" }}>RESOURCES</span>
                                {tutorialsResHovered || academyResHovered ?
                                    <div className="header-sub-nav-border" style={{ marginTop: -22 }}></div>
                                    :
                                    <></>
                                }
                            </div>
                        </div> */}
                        {/* {subDomainNo == 2 || subDomainNo == 3 ?
                            <div className='r-ac m-b-5' style={{ height: "4vh", marginBottom: academyTestHovered ? 15 : 5, marginTop: academyTestHovered ? -10 : 0 }}
                                onMouseMove={() => { setMenuHovered(true); setMenuNo(4); setTutHomeHovered(false); setTutorialsResHovered(false); setacademyHomeHovered(false); setacademyCourHovered(false); setacademyResHovered(false); setCoachingMedHovered(false); setCoachingEnggHovered(false) }}
                                onClick={() => {
                                    if (subDomainNo == 2) {
                                        window.location.assign('https://academy.ashreya.com/tests/asat')
                                    } else if (subDomainNo == 3) {
                                        window.location.assign('https://coaching.ashreya.com/tests/asat')
                                    }
                                }}>
                                <div className='color-primary text-xs menu-bar-text-wrap cursor-pointer'>
                                    <span className="element" style={{ fontWeight: academyTestHovered ? "bold" : "" }}>{subDomainNo == 2 ? "ASAT Junior" : "ASAT Senior"}</span>
                                    {academyTestHovered ?
                                        <div className="header-sub-nav-border" style={{ marginTop: -22 }}></div>
                                        :
                                        <></>
                                    }
                                </div>
                            </div>
                            :
                            <></>} */}
                            {subDomainNo == 3 ?
                        <div className='r-ac m-b-5' style={{ height: "4vh" }}
                            onMouseMove={() => { setMenuHovered(false); setTutHomeHovered(false); setTutorialsResHovered(false); setacademyHomeHovered(false); setacademyCourHovered(false); setacademyResHovered(false); setacademyTestHovered(false); setCoachingMedHovered(false); setCoachingEnggHovered(false) }}
                            onClick={() => {
                                if (subDomainNo == 1) {
                                    window.location.assign('https://tutorials.ashreya.com/facilities')
                                } else if (subDomainNo == 2) {
                                    window.location.assign('https://academy.ashreya.com/facilities')
                                } else if (subDomainNo == 3) {
                                    window.location.assign('https://coaching.ashreya.com/facilities')
                                }
                            }}
                        >
                            <div className='color-primary text-xs menu-bar-text-wrap cursor-pointer'>
                                <span className="element">FACILITIES</span>
                            </div>
                        </div>:<></>}
                        {subDomainNo == 1 ?
                            <div className='r-ac m-b-5' style={{ height: "4vh" }} onMouseMove={() => { setMenuHovered(false); setTutHomeHovered(false); setTutorialsResHovered(false); setacademyHomeHovered(false); setacademyCourHovered(false); setacademyResHovered(false); setacademyTestHovered(false); setCoachingMedHovered(false); setCoachingEnggHovered(false) }}
                                onClick={() => {
                                    window.location.assign('https://tutorials.ashreya.com/about')
                                }}>
                                <div className='color-primary text-xs menu-bar-text-wrap cursor-pointer'>
                                    <span className="element">ABOUT</span>
                                </div>
                            </div>
                            :
                            <></>
                        }
                        <div className='r-ac m-b-5' style={{ height: "4vh" }}
                            onMouseMove={() => { setMenuHovered(false); setTutHomeHovered(false); setTutorialsResHovered(false); setacademyHomeHovered(false); setacademyCourHovered(false); setacademyResHovered(false); setacademyTestHovered(false); setCoachingMedHovered(false); setCoachingEnggHovered(false) }}>
                            <div className='color-primary text-xs menu-bar-text-wrap cursor-pointer'
                                onClick={() => {
                                    if (subDomainNo == 1) {
                                        window.location.assign('https://tutorials.ashreya.com/contact')
                                    } else if (subDomainNo == 2) {
                                        window.location.assign('https://academy.ashreya.com/contact')
                                    } else if (subDomainNo == 3) {
                                        window.location.assign('https://coaching.ashreya.com/contact')
                                    }
                                }}>
                                <span className="element">CONTACT</span>
                            </div>
                        </div>
                    </div>
                    {menuHovered && subDomainNo == 1 && menuNo == 1 ? (
                        <div style={{ width: "20%", marginLeft: "7%", height: "fit-content", marginTop: -40 }} onMouseMove={() => { setTutHomeHovered(true) }}>
                            <div className='r-ac m-b-5' style={{ height: "4vh" }}>
                                <div className='color-primary text-xs cursor-pointer'
                                    onClick={() => {
                                        window.location.assign('https://tutorials.ashreya.com/')
                                    }}>
                                    <span className="subElement">SUBJECTS</span>
                                </div>
                            </div>
                            <div className='r-ac m-b-5' style={{ height: "4vh" }}>
                                <div className='color-primary text-xs cursor-pointer'
                                    onClick={() => {
                                        window.location.assign('https://tutorials.ashreya.com/')
                                    }}>
                                    <span className="subElement">WHY US</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        menuHovered && subDomainNo == 1 && menuNo == 3 ? (
                            <div style={{ width: "20%", marginLeft: "7%", marginTop: -80, height: "fit-content" }} onMouseMove={() => { setTutorialsResHovered(true) }}>
                                <div className='r-ac m-b-5' style={{ height: "4vh" }}>
                                    <div className='color-primary text-xs cursor-pointer'
                                        onClick={() => {
                                            // window.location.assign('https://tutorials.ashreya.com/resources/cbse')
                                        }}>
                                        <span className="subElement">CBSE</span>
                                    </div>
                                </div>
                                <div className='r-ac m-b-5' style={{ height: "4vh" }}>
                                    <div className='color-primary text-xs cursor-pointer'
                                        onClick={() => {
                                            // window.location.assign('https://tutorials.ashreya.com/resources/seba')
                                        }}>
                                        <span className="subElement">ASSEB</span>
                                    </div>
                                </div>
                                <div className='r-ac m-b-5' style={{ height: "4vh" }}>
                                    <div className='color-primary text-xs cursor-pointer'
                                        onClick={() => {
                                            // window.location.assign('https://tutorials.ashreya.com/resources/announcements')
                                        }}>
                                        <span className="subElement">Announcements</span>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            menuHovered && (subDomainNo == 2 || subDomainNo == 3) && menuNo == 1 ? (
                                <div className='sub-domain-menu' style={{ marginLeft: "7%", height: "fit-content", marginTop: -40 }} onMouseMove={() => { setacademyHomeHovered(true) }}>
                                    <div className='r-ac m-b-5' style={{ height: "4vh" }}>
                                        <div className='color-primary text-xs cursor-pointer'
                                            onClick={() => {
                                                if (subDomainNo == 2) {
                                                    window.location.assign('https://academy.ashreya.com/')
                                                } else if (subDomainNo == 3) {
                                                    window.location.assign('https://coaching.ashreya.com/')
                                                }
                                            }}>
                                            <span className="subElement">ashreya {subDomainNo == 2 ? "SIP" : "COACHING"}</span>
                                        </div>
                                    </div>
                                    <div className='r-ac m-b-5' style={{ height: "4vh" }}>
                                        <div className='color-primary text-xs cursor-pointer'
                                            onClick={() => {
                                                if (subDomainNo == 2) {
                                                    window.location.assign('https://academy.ashreya.com/')
                                                } else if (subDomainNo == 3) {
                                                    window.location.assign('https://coaching.ashreya.com/')
                                                }
                                            }}>
                                            <span className="subElement">BENEFITS OF THE PROGRAMME</span>
                                        </div>
                                    </div>
                                    <div className='r-ac m-b-5' style={{ height: "4vh" }}>
                                        <div className='color-primary text-xs cursor-pointer'
                                            onClick={() => {
                                                if (subDomainNo == 2) {
                                                    window.location.assign('https://academy.ashreya.com/')
                                                } else if (subDomainNo == 3) {
                                                    window.location.assign('https://coaching.ashreya.com/')
                                                }
                                            }}>
                                            <span className="subElement">BLOGS</span>
                                        </div>
                                    </div>
                                </div>)
                                : 
                                <></>
                                // (menuHovered && (subDomainNo == 2 || subDomainNo == 3) && menuNo == 2 ? (
                                //     <div style={{ width: "20%", marginLeft: "7%", height: "fit-content", marginTop: -90 }} onMouseMove={() => { setacademyCourHovered(true) }}>
                                //         <div className='r-ac m-b-5' style={{ height: "4vh" }}>
                                //             <div className='color-primary text-xs cursor-pointer'
                                //                 onMouseMove={() => {
                                //                     if (subDomainNo == 3) {
                                //                         setCoachingMedHovered(true);
                                //                         setCoachingEnggHovered(false)
                                //                     }
                                //                 }}
                                //                 onClick={() => {
                                //                     if (subDomainNo == 2) {
                                //                         window.location.assign('https://academy.ashreya.com/courses/medical')
                                //                     }
                                //                 }}>
                                //                 <span className="subElement" style={{ fontWeight: coachingMedHovered ? "bold" : "" }}>MEDICAL</span>
                                //             </div>
                                //             {coachingMedHovered ?
                                //                 <div className="header-sub-nav-border" style={{ marginTop: 22, marginLeft: "-21%", width: "21%" }}></div>
                                //                 :
                                //                 <></>
                                //             }
                                //         </div>
                                //         <div className='r-ac m-b-5' style={{ height: "4vh" }}>
                                //             <div className='color-primary text-xs cursor-pointer'
                                //                 onMouseMove={() => {
                                //                     if (subDomainNo == 3) {
                                //                         setCoachingEnggHovered(true);
                                //                         setCoachingMedHovered(false)
                                //                     }
                                //                 }}
                                //                 onClick={() => {
                                //                     if (subDomainNo == 2) {
                                //                         window.location.assign('https://academy.ashreya.com/courses/engineering')
                                //                     }
                                //                 }}>
                                //                 <span className="subElement" style={{ fontWeight: coachingEnggHovered ? "bold" : "" }}>ENGINEERING</span>
                                //             </div>
                                //             {coachingEnggHovered ?
                                //                 <div className="header-sub-nav-border" style={{ marginTop: 22, marginLeft: "-31%", width: "31%" }}></div>
                                //                 :
                                //                 <></>
                                //             }
                                //         </div>
                                //     </div>
                                // ) : (menuHovered && (subDomainNo == 2 || subDomainNo == 3) && menuNo == 3 ? (
                                //     <div style={{ width: "20%", marginLeft: "7%", height: "fit-content", marginTop: -80 }} onMouseMove={() => { setacademyResHovered(true) }}>
                                //         <div className='r-ac m-b-5' style={{ height: "4vh" }}>
                                //             <div className='color-primary text-xs cursor-pointer'
                                //                 onClick={() => {
                                //                     if (subDomainNo == 2) {
                                //                         window.location.assign('https://academy.ashreya.com/resources/ncert')
                                //                     } else if (subDomainNo == 3) {
                                //                         window.location.assign('https://coaching.ashreya.com/resources/ncert')
                                //                     }
                                //                 }}>
                                //                 <span className="subElement">NCERT</span>
                                //             </div>
                                //         </div>
                                //         <div className='r-ac m-b-5' style={{ height: "4vh" }}>
                                //             <div className='color-primary text-xs cursor-pointer'
                                //                 onClick={() => {
                                //                     if (subDomainNo == 2) {
                                //                         window.location.assign('https://academy.ashreya.com/resources/ahsec')
                                //                     } else if (subDomainNo == 3) {
                                //                         window.location.assign('https://coaching.ashreya.com/resources/ahsec')
                                //                     }
                                //                 }}>
                                //                 <span className="subElement">AHSEC</span>
                                //             </div>
                                //         </div>
                                //         <div className='r-ac m-b-5' style={{ height: "4vh" }}>
                                //             <div className='color-primary text-xs cursor-pointer'
                                //                 onClick={() => {
                                //                     if (subDomainNo == 2) {
                                //                         window.location.assign('https://academy.ashreya.com/resources/competative-exams')
                                //                     } else if (subDomainNo == 3) {
                                //                         window.location.assign('https://coaching.ashreya.com/resources/competative-exams')
                                //                     }
                                //                 }}>
                                //                 <span className="subElement">COMPETATIVE EXAMS</span>
                                //             </div>
                                //         </div>
                                //         <div className='r-ac m-b-5' style={{ height: "4vh" }}>
                                //             <div className='color-primary text-xs cursor-pointer'
                                //                 onClick={() => {
                                //                     if (subDomainNo == 2) {
                                //                         window.location.assign('https://academy.ashreya.com/resources/announcements')
                                //                     } else if (subDomainNo == 3) {
                                //                         window.location.assign('https://coaching.ashreya.com/resources/announcements')
                                //                     }
                                //                 }}>
                                //                 <span className="subElement">ANNOUNCEMENTS</span>
                                //             </div>
                                //         </div>
                                //     </div>
                                // )
                                //     :
                                //     (menuHovered && (subDomainNo == 2 || subDomainNo == 3) && menuNo == 4 ? (
                                //         <div style={{ width: "20%", marginLeft: "7%", height: "fit-content", marginTop: -200 }} onMouseMove={() => { setacademyTestHovered(true) }}>
                                //             {/* <div className='r-ac m-b-5' style={{ height: "4vh" }}>
                                //                 <div className='color-primary text-xs cursor-pointer'
                                //                     onClick={() => {
                                //                         if (subDomainNo == 2) {
                                //                             window.location.assign('https://academy.ashreya.com/tests/art')
                                //                         } else if (subDomainNo == 3) {
                                //                             window.location.assign('https://coaching.ashreya.com/tests/art')
                                //                         }
                                //                     }}>
                                //                     <span className="subElement">ART</span>
                                //                 </div>
                                //             </div> */}
                                //             {/* <div className='r-ac m-b-5' style={{ height: "4vh" }}>
                                //                 <div className='color-primary text-xs cursor-pointer'
                                //                     onClick={() => {
                                //                         if (subDomainNo == 2) {
                                //                             window.location.assign('https://academy.ashreya.com/tests/asat')
                                //                         } else if (subDomainNo == 3) {
                                //                             window.location.assign('https://coaching.ashreya.com/tests/asat')
                                //                         }
                                //                     }}>
                                //                     <span className="subElement">ASAT</span>
                                //                 </div>
                                //             </div> */}
                                //         </div>
                                //     )
                                //         :
                                //         (<div></div>))))
                        )
                    )}
                    {subDomainNo == 3 && coachingMedHovered ? (
                        <div style={{ width: "20%", marginLeft: "-7%", height: "fit-content", marginTop: -130 }} onMouseMove={() => { setCoachingMedHovered(true) }}>
                            {/* <div className='r-ac m-b-5' style={{ height: "4vh" }}>
                                <div className='color-primary text-xs cursor-pointer'
                                    onClick={() => {
                                        window.location.assign('https://coaching.ashreya.com/medical-target-batch')
                                    }}>
                                    <span className="subElement">TARGET NEET</span>
                                </div>
                            </div> */}
                            {/* <div className='r-ac m-b-5' style={{ height: "4vh" }}>
                                <div className='color-primary text-xs cursor-pointer'
                                    onClick={() => {
                                        window.location.assign('https://coaching.ashreya.com/medical-capsule-course')
                                    }}>
                                    <span className="subElement">CAPSULE NEET</span>
                                </div>
                            </div> */}
                        </div>
                    )
                        :
                        (
                            subDomainNo == 3 && coachingEnggHovered ? (
                                <div style={{ width: "20%", marginLeft: "-7%", height: "fit-content", marginTop: -130 }} onMouseMove={() => { setCoachingEnggHovered(true) }}>
                                    <div className='r-ac m-b-5' style={{ height: "4vh" }}>
                                        <div className='color-primary text-xs cursor-pointer'
                                            onClick={() => {
                                                window.location.assign('https://coaching.ashreya.com/engineering-target-batch')
                                            }}>
                                            <span className="subElement">TARGET JEE</span>
                                        </div>
                                    </div>
                                    {/* <div className='r-ac m-b-5' style={{ height: "4vh" }}>
                                        <div className='color-primary text-xs cursor-pointer'
                                            onClick={() => {
                                                window.location.assign('https://coaching.ashreya.com/engineering-capsule-course')
                                            }}>
                                            <span className="subElement">CAPSULE JEE</span>
                                        </div>
                                    </div> */}
                                </div>
                            )
                                :
                                (<div></div>)
                        )
                    }
                </div>
                :
                <></>
            }
            <div className="mobile-parent-header">
                <img
                    src={require("../../assets/ashreya-white-logo.svg").default}
                    alt="ashreya"
                    style={{ width: 150 }}
                    loading="lazy"
                />
                <div className='m-t-10' onClick={() => { setSideBar(true) }}>
                    <img src={require("../../assets/TopBar/hamburger.svg").default} alt="image123" loading="lazy"/>
                </div>
            </div>
            {isDigitalModalVisible &&
                <AshreyaDigitalModal
                    isDigitalModalVisible={isDigitalModalVisible}
                    setIsDigitalModalVisible={setIsDigitalModalVisible} />}
        </div>
    )
}
const mapStateToProps = (state) => {
    const { subDomainHovered } = state.ashreya
    return {
        subDomainHovered
    }
};
const mapDispatchToProps = (dispatch) => ({
    setSideBar: (val) => dispatch(setSideBar(val)),
    setSubDomainHovered: (val) => dispatch(setSubDomainHovered(val))
});
export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
// export default TopBar;
