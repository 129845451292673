import React from 'react'
import '../styles.css'
import { SchoolIntegratedBannerCardObj, SchoolIntegratedCard } from './webSchoolIntegratedBanner'

function MobSchoolIntegratedBanner() {
    return (
        <div className='p-h-20'>
            <div className='text-20 color-dark-black m-t-40'>Register to our</div>
            <div className='font-bold text-24 color-primary m-t-20'>School Integrated Program [2024-2026]</div>
            <div className='text-sm color-light-black m-t-20' style={{ lineHeight: 1.6 }}>ashreya Academy brings to you specially curated programmes to help you prepare for your entrance exams, enabling you to meet your aspirations without compromising your board preparations.</div>
            <div className='text-sm m-t-40 m-b-30' style={{ color: "#008445" }}></div>
            <SchoolIntegratedCard data={SchoolIntegratedBannerCardObj[0]} index={1} />
            <SchoolIntegratedCard data={SchoolIntegratedBannerCardObj[1]} index={2} />
            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}><img src={require("../../../assets/AshreyaHome/earlybird.svg").default} style={{ width: 120 }} alt='earlyBirdImage3' loading='lazy' /></div>
            <div className='text-xs font-bold m-t-30' style={{ color: "#B81B1B", textAlign: "right" }}>ADMISSIONS OPEN</div>
        </div>
    )
}

export default MobSchoolIntegratedBanner