import React from 'react'
import '../styles.css'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

function ScholarshipSection() {
    const scholarshipText1 = `Scholarships during admissions are a common thing, but have you ever heard of a scholarship that’s provided to an individual while/after bidding farewell to an organization? We bet you have not.`
    const scholarshipText2 = `Yes, ashreya has pioneered the most unique of scholarships designed for extraordinary students from the region. This scholarship is a passport to top govt colleges (engineering & medical)  for those who are unable to afford the expenses after clearing their entrance exams. While outstanding scores in NEET & JEE are a must, final shortlisting involves a meticulous process which also takes into account the financial status of the candidate’s family. Recipients will enjoy a 100% coverage of tuition fees for 4 or 5 years of engineering or medical studies. A commitment that proves our belief in your potential and the larger goal of providing quality education to at least the deserving, if not all.`
    return (
        <div>
            <center className='text-50 color-sec-black font-bold m-t-100 scholarship-margin mobile-font'>
                ashreya Scholarship
            </center>
            <div className='scholarship-parent'>
                <div className='m-t-70 text-sm color-light-black' style={{ lineHeight: 1.6 }}>{scholarshipText1}</div>
                <div className="r-sb m-t-20 mob-title-font" style={{ width: "100%" }}>
                    <div className="text-sm color-light-black m-t-10" style={{ width: "47%", textAlign: "justify", lineHeight: 1.6 }}>
                        {scholarshipText2}
                    </div>
                    <div className='r-c-c geeks' style={{ width: "48%" }}>
                        <img alt="image20" src={require("../../../assets/AshreyaHome/scholarshipImage.png")} style={{ width: "100%" }} loading="lazy" />
                    </div>
                </div>
            </div>
            <div className='scholarship-column'>
                <div className='p-h-20 m-t-50'><img alt="image27" src={require("../../../assets/AshreyaHome/scholarshipImage.png")} style={{ width: "100%" }} loading="lazy" /></div>
                <div className='p-h-20 m-t-20 text-sm color-light-black mob-title-font' style={{ lineHeight: 1.5, textAlign: "justify" }}>{scholarshipText1}</div>
                <div className='p-h-20 m-t-20 text-sm color-light-black mob-title-font' style={{ lineHeight: 1.5, textAlign: "justify" }}>{scholarshipText2}</div>
            </div>
            <div className='text-sm p-h-120 m-t-30 font-bold learn-more-parent' style={{ color: "#0066FF", display: "flex", justifyContent: "flex-end", width: "100%" }}><Link to="/scholarship"><div>LEARN MORE</div></Link></div>
        </div>
    )
}

export default ScholarshipSection