import React from 'react'
import '../styles.css'
import { useState } from 'react';

export const handleCoachingShare = (e, index, setShareUrl, setIsShareModalVisible) => {
    e.stopPropagation();
    if (index == 1) {
        setShareUrl(`https://ashreya.aulas.in/resource-details?id=13&type=course`);
    } else if (index == 2) {
        setShareUrl(`https://ashreya.aulas.in/resource-details?id=14&type=course`);
    } else if (index == 3) {
        setShareUrl(`https://ashreya.aulas.in/resource-details?id=17&type=course`);
    } else if (index == 4) {
        setShareUrl(`https://ashreya.aulas.in/resource-details?id=18&type=course`);
    }
    setIsShareModalVisible(true);
}

const WebCoachingBannerCard = ({ icon, text, amount, index, originalAmount, isShareModalVisible, setIsShareModalVisible, setShareUrl }) => {
    return (
        <>
            <div className='c-c-c cursor-pointer ban-coaching-zoom' style={{ width: "fit-content" }}>
                <img src={icon} alt="banner3-icon" className='banner3-line-icon' loading="lazy" />
                <div className='text-20 color-primary font-bold m-t-5 line-container-font1 m-t-2'>{text}</div>
                <div className='banner-nav-border r-c-c' style={{ width: index == 1 ? "90%" : index == 2 ? "80%" : index == 3 ? "90%" : "80%", marginTop: 0 }}></div>
                {index == 3 || index == 4 ? <div className='text-xsm color-black font-600 m-t-15'>[Study from home]</div> : <></>}
                <div className='text-md color-sec-black font-bold m-t-15 m-b-15 line-container-font1'>{amount}<span className='m-l-5 text-xsm' style={{ textDecoration: 'line-through', color: "gray" }}>{originalAmount}</span></div>
                <div className='r-c-c'>
                    <div className='r-c-c m-r-20 cursor-pointer line-cont-margin' onClick={(e) => handleCoachingShare(e, index, setShareUrl, setIsShareModalVisible)}>
                        <img alt='shareIcon2' className='share-icon' src={require("../../../assets/AshreyaHome/share-grey.png")} loading="lazy" />
                        <div className='text-xs color-grey m-l-5 line-container-font2'>SHARE</div>
                    </div>
                    <div className='line-container-font2' style={{ color: "#006AE7", cursor: "pointer" }}>KNOW MORE</div>
                </div>
            </div>
        </>
    )
}

function WebCoachingBanner({ isShareModalVisible, setIsShareModalVisible, setShareUrl }) {
    const [isHovered1, setIsHovered1] = useState('');
    const [isHovered2, setIsHovered2] = useState('');
    const [isHovered3, setIsHovered3] = useState('');
    const [isHovered4, setIsHovered4] = useState('');
    const shadowClass = 'shadow-effect';
    return (
        <div className='r-sb m-t-20 ashreya-banner-wrapper cursor-pointer'>
            <div style={{ width: "40%" }}>
                <div className='font-bold text-xlg color-primary m-t-20'>Coaching Programmes</div>
                <div className='text-20 color-light-black m-t-30' style={{ lineHeight: 1.6 }}>The ashreya Coaching Programmes are designed to assist students who aspire to pursue careers in medicine or engineering, helping them prepare for these fields effectively.</div>
                {/* <div className='text-20 color-light-black font-bold m-t-50' style={{ lineHeight: 1.6 }}>We are providing Early Bird Discount in Capsule Course - 25% discount if admission taken on or before 15th October</div> */}
                <div className='text-xsm font-bold m-t-60' style={{ color: "#B81B1B" }}>ADMISSIONS OPEN</div>
            </div>
            <div style={{ width: "60%", display: "flex", justifyContent: "flex-end" }}>
                {/* <div className='early-bird-position' style={{ display: "flex", alignItems: "flex-end",marginRight:"70px",  marginBottom:60 }}><img alt='earlyBirdImage' loading='lazy' src={require("../../../assets/discountfifteenPC.svg").default} style={{ width: "80%",}} /></div> */}
                <div className='line-container'>
                    <svg viewBox="0 0 706 394" fill="none" style={{ height: "380px" }}>
                        <path className={isHovered3 ? shadowClass : ''} d="M462.137 393.694H319.205C296.843 393.694 278.648 378.163 278.648 359.073V237.057C278.648 217.966 296.841 202.434 319.205 202.434H407.045C426.712 202.434 442.715 188.775 442.715 171.984L442.433 112.325H447.268L447.602 171.984C447.602 191.074 429.41 206.606 407.045 206.606H319.205C299.538 206.606 283.535 220.266 283.535 237.057V359.073C283.535 375.862 299.536 389.522 319.205 389.522H462.137C481.804 389.522 497.804 375.862 497.804 359.073L497.572 259.89H501.972L502.691 359.073C502.691 378.163 484.499 393.694 462.137 393.694Z" fill="#00BDE7" fill-opacity={isHovered3 ? "1" : "0.4"} />
                        <path className={isHovered2 ? shadowClass : ''} d="M230.566 166.114H225.809V41.115C225.809 18.8259 243.79 0.693359 265.896 0.693359H407.169C429.273 0.693359 447.256 18.8259 447.256 41.115V112.346H442.426V41.115C442.426 21.5125 426.611 5.56421 407.169 5.56421H265.896C246.456 5.56421 230.639 21.5125 230.639 41.115L230.566 166.114Z" fill="#FF8C05" fill-opacity={isHovered2 ? "1" : "0.4"} />
                        <path className={isHovered4 ? shadowClass : ''} d="M699.312 305.55V137.459C699.312 115.67 682.925 97.9414 662.781 97.9414H534.031C513.887 97.9414 497.5 115.668 497.5 137.459V209.476H497.572V259.893H501.974V204.714H501.902V137.459C501.902 118.296 516.315 102.703 534.031 102.703H662.781C680.497 102.703 694.91 118.294 694.91 137.459V305.55C691.569 306.587 689.115 309.92 689.115 313.863C689.115 318.631 692.701 322.513 697.111 322.513C701.521 322.513 705.107 318.633 705.107 313.863C705.107 309.92 702.654 306.587 699.314 305.55H699.312ZM697.111 317.751C695.129 317.751 693.517 316.007 693.517 313.863C693.517 311.719 695.129 309.975 697.111 309.975C699.093 309.975 700.705 311.719 700.705 313.863C700.705 316.007 699.093 317.751 697.111 317.751Z" fill="#FF00A8" fill-opacity={isHovered4 ? "1" : "0.4"} />
                        <path className={isHovered1 ? shadowClass : ''} d="M225.808 173.955L225.326 251.146C225.326 269.267 209.352 284.012 189.715 284.012H17.489C16.4265 280.594 13.0113 278.084 8.97156 278.084C4.08619 278.084 0.109375 281.753 0.109375 286.263C0.109375 290.774 4.08402 294.442 8.97156 294.442C13.0113 294.442 16.4265 291.933 17.489 288.517H189.717C212.043 288.517 230.208 271.754 230.208 251.148L230.566 166.116H225.808V173.955ZM8.97156 289.936C6.77499 289.936 4.98824 288.287 4.98824 286.259C4.98824 284.232 6.77499 282.583 8.97156 282.583C11.1681 282.583 12.9549 284.232 12.9549 286.259C12.9549 288.287 11.1681 289.936 8.97156 289.936Z" fill="#01CA39" fill-opacity={isHovered1 ? "1" : "0.4"} />
                    </svg>
                    <div style={{ position: "absolute", top: 20, right: 270, width: "fit-content" }} onClick={() => { window.location.assign('https://ashreya.aulas.in/') }} onMouseMove={() => { setIsHovered2(true) }} onMouseLeave={() => { setIsHovered2(false) }}>
                        <WebCoachingBannerCard
                            icon={require("../../../assets/AshreyaHome/coachingBannerIcon1.svg").default}
                            text={"Target NEET 2025"}
                            amount={"₹ 65,000"}
                            originalAmount={"₹ 80,000"}
                            index={1}
                            isShareModalVisible={isShareModalVisible}
                            setIsShareModalVisible={setIsShareModalVisible}
                            setShareUrl={setShareUrl} />
                    </div>
                    <div style={{ position: "absolute", top: 210, right: 220, width: "fit-content" }} onClick={() => { window.location.assign('https://ashreya.aulas.in/') }} onMouseMove={() => { setIsHovered3(true) }} onMouseLeave={() => { setIsHovered3(false) }}>
                        <WebCoachingBannerCard
                            icon={require("../../../assets/AshreyaHome/coachingBannerIcon2.svg").default}
                            text={"Target JEE 2025"}
                            amount={"₹ 65,000"}
                            originalAmount={"₹ 80,000"}
                            index={2}
                            isShareModalVisible={isShareModalVisible}
                            setIsShareModalVisible={setIsShareModalVisible}
                            setShareUrl={setShareUrl} />
                    </div>
                    <div style={{ position: "absolute", top: 70, right: 485, width: "fit-content" }} onClick={() => { window.location.assign('https://ashreya.aulas.in/') }} onMouseMove={() => { setIsHovered1(true) }} onMouseLeave={() => { setIsHovered1(false) }}>
                        <WebCoachingBannerCard
                            icon={require("../../../assets/AshreyaHome/coachingBannerIcon1.svg").default}
                            text={"Target NEET 2025"}
                            amount={"₹ 25,000"}
                            originalAmount={"₹ 50,000"}
                            index={3}
                            isShareModalVisible={isShareModalVisible}
                            setIsShareModalVisible={setIsShareModalVisible}
                            setShareUrl={setShareUrl} />
                    </div>
                    <div style={{ position: "absolute", top: 110, right: 15, width: "fit-content" }} onClick={() => { window.location.assign('https://ashreya.aulas.in/') }} onMouseMove={() => { setIsHovered4(true) }} onMouseLeave={() => { setIsHovered4(false) }}>
                        <WebCoachingBannerCard
                            icon={require("../../../assets/AshreyaHome/coachingBannerIcon2.svg").default}
                            text={"Target JEE 2025"}
                            amount={"₹ 25,000"}
                            originalAmount={"₹ 50,000"}
                            index={4}
                            isShareModalVisible={isShareModalVisible}
                            setIsShareModalVisible={setIsShareModalVisible}
                            setShareUrl={setShareUrl} />
                    </div>
                </div>
                <div className='line-container2'>
                    <svg viewBox="0 0 348 253" fill="none" style={{ height: "380px" }}>
                        <path className={isHovered3 ? shadowClass : ''} d="M227.52 252.653H157.169C146.162 252.653 137.207 242.694 137.207 230.453V152.214C137.207 139.973 146.161 130.013 157.169 130.013H200.404C210.084 130.013 217.961 121.254 217.961 110.488L217.822 72.2334H220.202L220.366 110.488C220.366 122.729 211.412 132.688 200.404 132.688H157.169C147.489 132.688 139.612 141.447 139.612 152.214V230.453C139.612 241.219 147.488 249.978 157.169 249.978H227.52C237.2 249.978 245.076 241.219 245.076 230.453L244.961 166.855H247.127L247.481 230.453C247.481 242.694 238.527 252.653 227.52 252.653Z" fill="#00BDE7" fill-opacity={isHovered3 ? "1" : "0.4"} />
                        <path className={isHovered2 ? shadowClass : ''} d="M113.541 106.724H111.199V26.5715C111.199 12.2793 120.05 0.652344 130.93 0.652344H200.465C211.344 0.652344 220.196 12.2793 220.196 26.5715V72.2466H217.818V26.5715C217.818 14.002 210.034 3.77564 200.465 3.77564H130.93C121.362 3.77564 113.577 14.002 113.577 26.5715L113.541 106.724Z" fill="#FF8C05" fill-opacity={isHovered2 ? "1" : "0.4"} />
                        <path className={isHovered4 ? shadowClass : ''} d="M344.258 196.133V88.3496C344.258 74.3776 336.192 63.0098 326.277 63.0098H262.906C252.992 63.0098 244.926 74.3762 244.926 88.3496V134.528H244.961V166.856H247.128V131.475H247.092V88.3496C247.092 76.0617 254.187 66.0631 262.906 66.0631H326.277C334.997 66.0631 342.091 76.0603 342.091 88.3496V196.133C340.446 196.798 339.239 198.935 339.239 201.463C339.239 204.521 341.004 207.009 343.174 207.009C345.345 207.009 347.11 204.522 347.11 201.463C347.11 198.935 345.903 196.798 344.259 196.133H344.258ZM343.174 203.956C342.199 203.956 341.405 202.838 341.405 201.463C341.405 200.089 342.199 198.97 343.174 198.97C344.15 198.97 344.943 200.089 344.943 201.463C344.943 202.838 344.15 203.956 343.174 203.956Z" fill="#FF00A8" fill-opacity={isHovered4 ? "1" : "0.4"} />
                        <path className={isHovered1 ? shadowClass : ''} d="M111.198 111.751L110.961 161.247C110.961 172.867 103.099 182.322 93.4333 182.322H8.66361C8.14065 180.13 6.45968 178.521 4.47134 178.521C2.06676 178.521 0.109375 180.873 0.109375 183.765C0.109375 186.658 2.0657 189.01 4.47134 189.01C6.45968 189.01 8.14065 187.401 8.66361 185.21H93.4344C104.423 185.21 113.364 174.462 113.364 161.248L113.54 106.725H111.198V111.751ZM4.47134 186.12C3.39019 186.12 2.51075 185.063 2.51075 183.763C2.51075 182.463 3.39019 181.406 4.47134 181.406C5.5525 181.406 6.43193 182.463 6.43193 183.763C6.43193 185.063 5.5525 186.12 4.47134 186.12Z" fill="#01CA39" fill-opacity={isHovered1 ? "1" : "0.4"} />
                    </svg>
                    <div style={{ position: "absolute", top: 30, right: 200, width: "fit-content" }} onClick={() => { window.location.assign('https://ashreya.aulas.in/') }} onMouseMove={() => { setIsHovered2(true) }} onMouseLeave={() => { setIsHovered2(false) }}>
                        <WebCoachingBannerCard
                            icon={require("../../../assets/AshreyaHome/coachingBannerIcon1.svg").default}
                            text={"Target NEET 2025"}
                            amount={"₹ 65,000"}
                            originalAmount={"₹ 80,000"}
                            index={1}
                            isShareModalVisible={isShareModalVisible}
                            setIsShareModalVisible={setIsShareModalVisible}
                            setShareUrl={setShareUrl} />
                    </div>
                    <div style={{ position: "absolute", top: 220, right: 160, width: "fit-content" }} onClick={() => { window.location.assign('https://ashreya.aulas.in/') }} onMouseMove={() => { setIsHovered3(true) }} onMouseLeave={() => { setIsHovered3(false) }}>
                        <WebCoachingBannerCard
                            icon={require("../../../assets/AshreyaHome/coachingBannerIcon2.svg").default}
                            text={"Target JEE 2025"}
                            amount={"₹ 65,000"}
                            originalAmount={"₹ 80,000"}
                            index={2}
                            isShareModalVisible={isShareModalVisible}
                            setIsShareModalVisible={setIsShareModalVisible}
                            setShareUrl={setShareUrl} />
                    </div>
                    <div style={{ position: "absolute", top: 80, right: 370, width: "fit-content" }} onClick={() => { window.location.assign('https://ashreya.aulas.in/') }} onMouseMove={() => { setIsHovered1(true) }} onMouseLeave={() => { setIsHovered1(false) }}>
                        <WebCoachingBannerCard
                            icon={require("../../../assets/AshreyaHome/coachingBannerIcon1.svg").default}
                            text={"Target NEET 2025"}
                            amount={"₹ 25,000"}
                            originalAmount={"₹ 50,000"}
                            index={3}
                            isShareModalVisible={isShareModalVisible}
                            setIsShareModalVisible={setIsShareModalVisible}
                            setShareUrl={setShareUrl} />
                    </div>
                    <div style={{ position: "absolute", top: 130, right: 10, width: "fit-content" }} onClick={() => { window.location.assign('https://ashreya.aulas.in/') }} onMouseMove={() => { setIsHovered4(true) }} onMouseLeave={() => { setIsHovered4(false) }}>
                        <WebCoachingBannerCard
                            icon={require("../../../assets/AshreyaHome/coachingBannerIcon2.svg").default}
                            text={"Target JEE 2025"}
                            amount={"₹ 25,000"}
                            originalAmount={"₹ 50,000"}
                            index={4}
                            isShareModalVisible={isShareModalVisible}
                            setIsShareModalVisible={setIsShareModalVisible}
                            setShareUrl={setShareUrl} />
                    </div>
                </div>
                <div className='line-container3'>
                    <svg viewBox="0 0 457 394" fill="none" style={{ height: "380px" }}>
                        <path className={isHovered3 ? shadowClass : ''} d="M298.952 393.958H206.502C192.038 393.958 180.27 378.428 180.27 359.338V237.321C180.27 218.231 192.036 202.699 206.502 202.699H263.318C276.039 202.699 286.39 189.039 286.39 172.248L286.207 112.59H289.335L289.55 172.248C289.55 191.339 277.784 206.871 263.318 206.871H206.502C193.781 206.871 183.43 220.53 183.43 237.321V359.338C183.43 376.127 193.78 389.786 206.502 389.786H298.952C311.672 389.786 322.022 376.127 322.022 359.338L321.872 260.155H324.717L325.183 359.338C325.183 378.428 313.416 393.958 298.952 393.958Z" fill="#00BDE7" fill-opacity={isHovered3 ? "1" : "0.4"} />
                        <path className={isHovered2 ? shadowClass : ''} d="M149.171 166.379H146.094V41.3796C146.094 19.0906 157.724 0.958008 172.022 0.958008H263.4C277.696 0.958008 289.328 19.0906 289.328 41.3796V112.611H286.204V41.3796C286.204 21.7771 275.975 5.82886 263.4 5.82886H172.022C159.449 5.82886 149.218 21.7771 149.218 41.3796L149.171 166.379Z" fill="#FF8C05" fill-opacity={isHovered2 ? "1" : "0.4"} />
                        <path className={isHovered4 ? shadowClass : ''} d="M452.358 305.815V137.724C452.358 115.934 441.759 98.2061 428.73 98.2061H345.453C332.424 98.2061 321.824 115.932 321.824 137.724V209.74H321.871V260.157H324.718V204.979H324.672V137.724C324.672 118.561 333.994 102.968 345.453 102.968H428.73C440.188 102.968 449.511 118.559 449.511 137.724V305.815C447.35 306.852 445.763 310.185 445.763 314.128C445.763 318.896 448.082 322.777 450.935 322.777C453.787 322.777 456.107 318.898 456.107 314.128C456.107 310.185 454.52 306.852 452.36 305.815H452.358ZM450.935 318.015C449.653 318.015 448.61 316.272 448.61 314.128C448.61 311.984 449.653 310.24 450.935 310.24C452.217 310.24 453.259 311.984 453.259 314.128C453.259 316.272 452.217 318.015 450.935 318.015Z" fill="#FF00A8" fill-opacity={isHovered4 ? "1" : "0.4"} />
                        <path className={isHovered1 ? shadowClass : ''} d="M146.094 174.219L145.782 251.41C145.782 269.531 135.45 284.277 122.748 284.277H11.3507C10.6634 280.859 8.45445 278.349 5.84153 278.349C2.68162 278.349 0.109375 282.017 0.109375 286.528C0.109375 291.039 2.68022 294.707 5.84153 294.707C8.45445 294.707 10.6634 292.198 11.3507 288.781H122.75C137.19 288.781 148.939 272.019 148.939 251.412L149.171 166.381H146.094V174.219ZM5.84153 290.2C4.42076 290.2 3.26508 288.551 3.26508 286.524C3.26508 284.497 4.42076 282.848 5.84153 282.848C7.2623 282.848 8.41798 284.497 8.41798 286.524C8.41798 288.551 7.2623 290.2 5.84153 290.2Z" fill="#01CA39" fill-opacity={isHovered1 ? "1" : "0.4"} />
                    </svg>
                    <div style={{ position: "absolute", top: 30, right: 165, width: "fit-content" }} onClick={() => { window.location.assign('https://ashreya.aulas.in/') }} onMouseMove={() => { setIsHovered2(true) }} onMouseLeave={() => { setIsHovered2(false) }}>
                        <WebCoachingBannerCard
                            icon={require("../../../assets/AshreyaHome/coachingBannerIcon1.svg").default}
                            text={"Target NEET 2025"}
                            amount={"₹ 65,000"}
                            originalAmount={"₹ 80,000"}
                            index={1}
                            isShareModalVisible={isShareModalVisible}
                            setIsShareModalVisible={setIsShareModalVisible}
                            setShareUrl={setShareUrl} />
                    </div>
                    <div style={{ position: "absolute", top: 230, right: 130, width: "fit-content" }} onClick={() => { window.location.assign('https://ashreya.aulas.in/') }} onMouseMove={() => { setIsHovered3(true) }} onMouseLeave={() => { setIsHovered3(false) }}>
                        <WebCoachingBannerCard
                            icon={require("../../../assets/AshreyaHome/coachingBannerIcon2.svg").default}
                            text={"Target JEE 2025"}
                            amount={"₹ 65,000"}
                            originalAmount={"₹ 80,000"}
                            index={2}
                            isShareModalVisible={isShareModalVisible}
                            setIsShareModalVisible={setIsShareModalVisible}
                            setShareUrl={setShareUrl} />
                    </div>
                    <div style={{ position: "absolute", top: 90, right: 310, width: "fit-content" }} onClick={() => { window.location.assign('https://ashreya.aulas.in/') }} onMouseMove={() => { setIsHovered1(true) }} onMouseLeave={() => { setIsHovered1(false) }}>
                        <WebCoachingBannerCard
                            icon={require("../../../assets/AshreyaHome/coachingBannerIcon1.svg").default}
                            text={"Target NEET 2025"}
                            amount={"₹ 25,000"}
                            originalAmount={"₹ 50,000"}
                            index={3}
                            isShareModalVisible={isShareModalVisible}
                            setIsShareModalVisible={setIsShareModalVisible}
                            setShareUrl={setShareUrl} />
                    </div>
                    <div style={{ position: "absolute", top: 130, right: 0, width: "fit-content" }} onClick={() => { window.location.assign('https://ashreya.aulas.in/') }} onMouseMove={() => { setIsHovered4(true) }} onMouseLeave={() => { setIsHovered4(false) }}>
                        <WebCoachingBannerCard
                            icon={require("../../../assets/AshreyaHome/coachingBannerIcon2.svg").default}
                            text={"Target JEE 2025"}
                            amount={"₹ 25,000"}
                            originalAmount={"₹ 50,000"}
                            index={4}
                            isShareModalVisible={isShareModalVisible}
                            setIsShareModalVisible={setIsShareModalVisible}
                            setShareUrl={setShareUrl} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WebCoachingBanner