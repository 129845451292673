import Axios from "axios";
import Notification from "../services/Notification";
import Api from "../services/Api";
export const SET_SIDEBAR = "SET_SIDEBAR";
export const SET_SUB_DOMAIN_HOVERED = "SET_SUB_DOMAIN_HOVERED";
export const SET_BLOG_DETAIL = "SET_BLOG_DETAIL"

export const setSideBar = val => ({
    type: SET_SIDEBAR,
    val
})
export const setSubDomainHovered = (val) => ({
    type: SET_SUB_DOMAIN_HOVERED,
    val
})
export const setBlogDetail = (val) => ({
    type: SET_BLOG_DETAIL,
    val
})
export const submitAshreyaForm = (params, callBack = null, successCallBack = null) => {
    return dispatch => {
        return Api.post("/api/enroll-ashreya")
            .params((params))
            .send((response, error) => {
                if (callBack) {
                    callBack()
                }
                if (response != undefined) {
                    if (successCallBack) {
                        successCallBack()
                    }
                }
                if (response && response.show.type === "success") {
                    successCallBack()
                    Notification.success(response.show.message);
                } else if (response && response.show.type === "error") {
                    Notification.error(response.show.message);
                }
            });
    };
};