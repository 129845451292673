import { Modal } from 'antd'
import React from 'react'

function AshreyaDigitalModal({ isDigitalModalVisible, setIsDigitalModalVisible }) {
    const handleCancel = () => {
        setIsDigitalModalVisible(false)
    }
    return (
        <Modal
            centered
            visible={isDigitalModalVisible}
            onOk={handleCancel}
            onCancel={handleCancel}
            header={null}
            footer={null}
            width='55%'
        >
            <div style={{ padding: 20 }}>
                <div className="text-md color-primary font-bold">Download the ashreya app</div>
                <div className="text-sm color-black m-t-10" style={{ lineHeight: 1.5, width: "70%" }}>Prepare for JEE/NEET the smart way and fulfill your dreams of becoming a Doctor or an Engineer.</div>
                <div className='r-sb' style={{ width: "100%" }}>
                    <div style={{ width: "43%", }}>
                        <div className='r-sb' style={{ width: "100%" }}>
                            <div>
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.ashreya.aulas&hl=en-IN"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        style={{ width: 130, height: 80 }}
                                        src={require("../assets/Footer/googlePlay.svg").default}
                                        alt="googlePlay"
                                        loading="lazy"
                                    />
                                </a>
                                <img
                                    loading="lazy"
                                    alt="googlestoreQr"
                                    src={require("../assets/Footer/googlestoreQr.png")} style={{ height: 130, width: 130, border: "none" }} />
                            </div>
                            <div style={{ textAlign: "right" }}>
                                <a
                                    href="https://apps.apple.com/us/app/aulas/id1564724183?platform=iphone"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        style={{ width: 130, height: 80 }}
                                        src={require("../assets/Footer/appStore.svg").default}
                                        alt="appStore"
                                        loading="lazy"
                                    />
                                </a>
                                <img
                                    alt="appstoreQr"
                                    loading="lazy"
                                    src={require("../assets/Footer/appstoreQr.png")} style={{ height: 130, width: 130, border: "none" }} />
                            </div>
                        </div>
                        <div className='m-t-10 m-b-10 r-c-c' style={{ color: "#140B1E" }}>OR</div>
                        <div className='text-xs r-c-c'>visit :<a
                            href=" https://ashreya.aulas.in"
                            target="_blank"
                            rel="Vectors Academy Web Portal"
                        >{"  "} ashreya.aulas.in</a></div>
                    </div>
                    <div style={{ width: "55%" }}>
                        <img loading="lazy" alt="LearningApp" src={require("../assets/About/LearningApp.jpg")} style={{ width: "100%" }} />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default AshreyaDigitalModal