import React from 'react';
import { message } from 'antd';

const Notification = {
    success(msg) {
        message.success(msg);
    },

    error(msg) {
        message.error(msg);
    }

}
export default Notification;