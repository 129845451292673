import { Button, Input, Space, Row, Col, Popover, Form } from "antd";
import React from "react";
import "./styles.css";
import { useState, useEffect } from "react";
import PhoneInput from "react-phone-number-input";
import ReactGA from 'react-ga';
import img1 from "../../assets/Scholarship/carouselImg1.png";
import img2 from "../../assets/Scholarship/carouselImg2.png";
import img3 from "../../assets/Scholarship/carouselImg3.png";
import img4 from "../../assets/Scholarship/carouselImg4.png";
import img5 from "../../assets/Scholarship/carouselImg5.png";
import { styled } from "styled-components";
import { Hidden, Visible } from "react-grid-system";
import { connect } from "react-redux";
import { setSubDomainHovered, submitAshreyaForm } from "../../action/ashreya";

function Scholarship({ submitAshreyaForm, setSubDomainHovered, subDomainHovered }) {
  const [values, setValues] = useState(0);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [buttonLoader, toggleButtonLoader] = useState(false);
  const clearValues = () => {
    setName("");
    setPhone("");
  }
  const onSubmit = () => {
    toggleButtonLoader(true);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "scholarship_enrollment",
      "gtm.formSubmit": "scholarship_enrollment"
    });
    const params = {
      studentName: name,
      phone: phone,
    }
    submitAshreyaForm(
      params,
      () => {
        toggleButtonLoader(false);
      },
      () => { clearValues() })
  }

  const Obj = [
    {
      img: img1,
    },
    {
      img: img2,
    },
    {
      img: img3,
    },
    {
      img: img4,
    },
    {
      img: img5,
    },
  ];

  const length = Obj.length;
  const setCustomValue = (value) => {
    setValues(value);
  };

  useEffect(() => {
    if (!reset) {
      const mount = setInterval(() => {
        setValues((prev) => {
          if (prev == length - 1) {
            return prev - (length - 1);
          } else {
            return prev + 1;
          }
        });
      }, 5000);
      return () => clearInterval(mount);
    }
  }, [reset]);

  const ValuesCarousal = styled.div`
    .lines {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }
    .blueline {
      width: 60px;
      height: 6px;
      background-color: #9671BC;
      border-radius: 3px;
      cursor: pointer;
    }
    .greyline {
      width: 12px;
      height: 6px;
      background-color: #9d9d9d;
      border-radius: 3px;
      cursor: pointer;
    }
    .carousal-image {
      width: 100%;
      height: 100%;
    }
    .image-container {
      display: flex;
      justify-content: flex-end;
      align-items: strech;
      width: 50%;
      height: 50%;
    }
  `;
  const scholarshipDesc1 = "Exclusively designed for remarkable students within our institute and the wider Northeast region, the ashreya Scholarship is a passport to top-tier engineering and medical colleges. With outstanding NEET and JEE scores, these scholars are chosen through meticulous selection. Enjoy the journey as we cover full tuition fees for the 4 to 5-year academic voyage – a commitment that defines our belief in your potential. Let ashreya be your partner in achieving greatness."
  const scholarshipDesc2 = "At ashreya, we're driven by our passion for providing quality education to everyone. We believe that education should be a fundamental right and not a privilege reserved for a select few. That's why we've created the ashreya Scholarship initiative."
  return (
    <div>
      <div onMouseMove={() => setSubDomainHovered(false)} className={`${subDomainHovered ? "bg-image" : ""}`}>
        <div className="section1-parent">
          <div className="text-40 color-sec-black font-bold section1-sub-parent1 mobile-font">
            ashreya SCHOLARSHIP
          </div>
          <div className="section1-sub-parent2" style={{ width: "100%" }}>
            <div className="text-sm color-light-black section1-child1 mob-desc-font" style={{ textAlign: "justify", lineHeight: 1.6 }}>
              {scholarshipDesc1}
              <div style={{ marginTop: "1rem" }}>
                {scholarshipDesc2}
              </div>
            </div>
            <ValuesCarousal
              className="section1-child2"
            >
              <Row
                style={{
                  height: "42vh",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
                className="image-container"
              >
                <img
                  src={Obj[values].img}
                  alt="Carousal Banner"
                  className="carousal-image"
                  loading="lazy"
                />
              </Row>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                  width: "100%",
                  height: "18%",
                }}
              >
                <div className="lines">
                  {Obj.map((ele, index) => {
                    return (
                      <div
                        className={values === index ? "blueline" : "greyline"}
                        onClick={() => {
                          setCustomValue(index);
                          setReset(!reset);
                        }}
                        onMouseLeave={() => setReset(!reset)}
                      ></div>
                    );
                  })}
                </div>
              </Row>
            </ValuesCarousal>
          </div>
        </div>
        <div className="section2-parent" >
          <center className="c-c-c section2-child1">
            <center className="aspiring-text-45 color-sec-black font-bold mobile-font">
              Aspiring for Medical
              <div className="m-t-10">or Engineering?</div>
            </center>
            <div className="text-md aspiring-text-md color-sec-black m-t-30">
              Avail <span className="font-bold">100% scholarship</span> through ashreya
            </div>
          </center>
          <div className='section2-child2' >
            <div className="text-35 color-sec-black text-center m-b-20 font-bold mob-enroll-font">
              ENROLL TODAY!
            </div>
            <div style={{ width: "100%" }}>
              <Form
                name="basic"
                wrapperCol={{
                  span: 24,
                }}
                initialValues={{
                  remember: true,
                }}
                shouldUpdate={true}
                autoComplete="off"
                onFinish={onSubmit}
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your Full Name!'
                    },
                  ]}
                >
                  <Input
                    value={name}
                    placeholder='Full name'
                    onChange={(e) => setName(e.target.value)}
                    style={{ height: 45, width: "100%" }}
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Please input your phone number!',
                    },
                  ]}
                >
                  <PhoneInput
                    defaultCountry='IN'
                    value={phone}
                    placeholder='Mobile Number'
                    className="phone-input-wrapper"
                    style={{ border: "1px solid #d9d9d9", paddingLeft: 10, height: 45, width: "100%", backgroundColor: "white", borderRadius: 5 }}
                    onChange={(e) => setPhone(e)}
                  />
                </Form.Item>
                <Form.Item
                  wrapperCol={{
                    span: 24,
                  }}
                >
                  <Button
                    htmlType="submit"
                    disabled={name === "" || phone === ""}
                    className='ant-btn m-t-10 text-sm primary-btn'
                    loading={buttonLoader}
                  >
                    SUBMIT
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
        <div className="section3-parent">
          <div className="text-50 color-sec-black text-center font-bold mobile-font">
            Scholarship details
          </div>
        </div>
        <div className="section3-child">
          <div className=" text-20 color-primary font-bold">For Medical Students (NEET)</div>
          <div className="m-t-10 text-sm color-light-black mob-desc-font" style={{ lineHeight: 1.6 }}>Exceptional candidates from ashreya and northeast with top NEET entrance test scores chosen via selection process; granted full 5-year tuition coverage.</div>
          <div className="m-t-30 text-20 color-primary font-bold">For Engineering Students (JEE)</div>
          <div className="m-t-10 text-sm color-light-black mob-desc-font" style={{ lineHeight: 1.6 }}> Outstanding achievers from northeast or a part of ashreya, excelling in JEE entrance exams, selected internally, awarded full 4-year tuition coverage in leading colleges.</div>
        </div>
        <div className="section4-parent">
          <div className="text-50 color-sec-black text-center font-bold mobile-font">
            Recipients
          </div>
        </div>
        <div className="r-c-c section4-child" style={{ width: "100%" }}>
          <div className="c-c-c photo-div">
            <img
              alt="recipentsImg"
              src={require("../../assets/Scholarship/recipentsImg.jpg")}
              className="schol-photo"
              loading="lazy"
            />
            <div className="text-xsm color-sec-black mob-desc-font" style={{ margin: "15px 20px 20px 20px", textAlign: "justify", lineHeight: 1.6 }}>
              We're proud to announce that <span className="font-bold">Bitopan Kalita</span>, who scored a
              remarkable <span className="font-bold">655 marks in NEET UG, 2022,</span> to secure a seat in
              <span className="font-bold">AIIMS, Guwahati</span> is the first recipient of this coveted
              scholarship.<span className="font-bold"> Bitopan has received a total sum of Rs 2.5 lakh
                from Vectors Academy towards payment of his tuition fee at
                AIIMS, Guwahati for a period of 5 years.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  const { subDomainHovered } = state.ashreya
  return {
    subDomainHovered
  }
};
const mapDispatchToProps = (dispatch) => ({
  submitAshreyaForm: (params, callback, successCallback) => (dispatch(submitAshreyaForm(params, callback, successCallback))),
  setSubDomainHovered: (val) => dispatch(setSubDomainHovered(val))
});
export default connect(mapStateToProps, mapDispatchToProps)(Scholarship);
// export default Scholarship;
