import { Modal } from 'antd'
import React from 'react'
import { useState } from 'react';
import "./modalStyles.css"

function ShareCourseModal({ isShareModalVisible, setIsShareModalVisible, shareUrl }) {
    const [copied, setCopied] = useState(false);
    const handleCancel = () => {
        setIsShareModalVisible(false)
    }
    const handleCopyClick = () => {
        const urlInput = document.getElementById('url-input');
        urlInput.select();
        document.execCommand('copy');
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 10000);
    };
    const openChatPage = (platform) => {
        let chatUrl = '';
        switch (platform) {
            case 'instagram':
                chatUrl = 'https://www.instagram.com/direct/inbox/';
                break;
            case 'facebook':
                chatUrl = 'https://www.facebook.com/';
                break;
            case 'linkedin':
                chatUrl = 'https://www.linkedin.com/messaging/';
                break;
            case 'whatsapp':
                chatUrl = 'https://web.whatsapp.com/';
                break;
            default:
                return;
        }
        window.open(chatUrl, '_blank');
    };
    return (
        <Modal
            centered
            visible={isShareModalVisible}
            onOk={handleCancel}
            onCancel={handleCancel}
            header={null}
            footer={null}
        >
            <center className='text-20 font-bold m-t-10'>Share this course</center>
            <center className='text-sm m-t-20' style={{ color: "#747272" }}>Share your friends what they can learn from ashreya</center>
            <div className='m-t-20 r-sb p-h-70 share-modal-padding'>
                <div className='cursor-pointer' onClick={() => openChatPage('instagram')}>
                    <img alt="insta" src={require("../assets/AshreyaHome/shareInstagram.svg").default} loading="lazy"/>
                    <div className='text-xxs color-light-black' style={{ marginTop: -5 }}>Instagram</div>
                </div>
                <div className='cursor-pointer' onClick={() => openChatPage('facebook')}>
                    <img alt="fb" src={require("../assets/AshreyaHome/shareFacebook.svg").default} loading="lazy"/>
                    <div className='text-xxs color-light-black' style={{ marginTop: -5 }}>Facebook</div>
                </div>
                <div className='cursor-pointer' onClick={() => openChatPage('linkedin')}>
                    <img alt="linkedIn" src={require("../assets/AshreyaHome/shareLinkedIn.svg").default} loading="lazy"/>
                    <div className='text-xxs color-light-black' style={{ marginTop: -5 }}>LinkedIn</div>
                </div>
                <div className='cursor-pointer' onClick={() => openChatPage('whatsapp')}>
                    <img alt="whatsapp" src={require("../assets/AshreyaHome/shareWhatsapp.svg").default} style={{ marginTop: 6 }} loading="lazy"/>
                    <div className='text-xxs color-light-black' style={{ marginLeft: -10 }}>WhatsApp</div>
                </div>
            </div>
            <div className='r-sb p-h-30 m-t-20 m-b-20 r-c-c share-link-input-padding'>
                <input type="text" id="url-input" readOnly style={{ height: "fit-content", width: "90%", border: "1px solid #D8D8D8", marginRight: 20, fontSize: 13, color: "#747272", padding: 10 }} value={shareUrl} />
                <div className='text-xs color-sec-primary font-bold cursor-pointer' onClick={handleCopyClick}>{copied ? 'COPIED' : 'COPY'}</div>
            </div>
        </Modal>
    )
}

export default ShareCourseModal