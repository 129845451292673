import React from 'react'
import '../styles.css'

export const WhyAshreyaMobData = [
    {
      title: "Highly Experienced IITian Mentorship",
      icon: require("../../../assets/AshreyaHome/whyAshreyaMobIcon1.svg").default,
      text: "Only the most experienced and skilled IITians from the region are handpicked for the job.",
    },
    {
      title: "State-of-the-art Infrastructure",
      icon: require("../../../assets/AshreyaHome/whyAshreyaMobIcon2.svg").default,
      text: "Centralised AC campus, Smart Classrooms, Sophisticated Laboratories, High-Tech Support.",
    },
    {
      title: "Small Batch Advantage",
      icon: require("../../../assets/AshreyaHome/whyAshreyaMobIcon3.svg").default,
      text: "Focused learning in small groups ensures personalized guidance for every student.",
    },
    {
      title: "Instant Doubt Resolution",
      icon: require("../../../assets/AshreyaHome/whyAshreyaMobIcon4.svg").default,
      text: "Mentors are available 24x7 for on-demand instant doubt resolution. ",
    },
    {
      title: "Progress Tracking & Partnering with parents",
      icon: require("../../../assets/AshreyaHome/whyAshreyaMobIcon5.svg").default,
      text: "Pace of student growth monitored through regular assessments. Attendance & Grade Cards regularly shared with parents.",
    },
    {
      title: "Online/Offline on demand",
      icon: require("../../../assets/AshreyaHome/whyAshreyaMobIcon6.svg").default,
      text: "Equipped with multiple (offline/online/hybrid) modes of instruction, content-sharing, assessments & evaluation through ashreya App.",
    },
  ];

function WhyAshreyaMobileCard({ key, data }) {
  return (
    <>
        <div key={key} className='whyAshreyaMobCard'>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className='r-c-c'>
              <img alt="whyUs" src={data.icon} style={{ width: 50, height: 50 }} loading="lazy" />
              <div className='text-sm color-sec-primary m-l-20 ' style={{ width: "100%" }}>{data.title}</div>
            </div>
            <div className='text-sm m-t-10' style={{ lineHeight: 1.6, color: "#5B5462", }}>{data.text}</div>
          </div>
        </div>
        <div className="mobile-grey-line" style={{ width: "100%", margin: "20px 0px 20px 0px" }}></div>
      </>
  )
}

export default WhyAshreyaMobileCard