import React from 'react'
import { connect } from 'react-redux';
import { setSubDomainHovered } from '../action/ashreya';

function AsatRegister({setSubDomainHovered, subDomainHovered}) {
  return (
    <div style={{padding:"100px 0px 30px 0px"}} onMouseMove={() => setSubDomainHovered(false)} className={`r-c-c ${subDomainHovered ? "bg-image" : ""}`}>
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScKQCryRA8__M2gEWdr7FTBUVIpiCzL0cny9ZMcKIf658wgFw/viewform?embedded=true" width="1000" height="500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
  )
}
const mapStateToProps = (state) => {
    const { subDomainHovered } = state.ashreya
    return {
      subDomainHovered
    }
  };
  const mapDispatchToProps = (dispatch) => ({
    setSubDomainHovered: (val) => dispatch(setSubDomainHovered(val))
  });
  export default connect(mapStateToProps, mapDispatchToProps)(AsatRegister);