import React, { useEffect, useRef, useState } from 'react'
import { Link } from "react-router-dom";
import './styles.css'
import Carousel from 'react-multi-carousel';
import Slider from "react-slick";
import { connect } from 'react-redux';
import { setSubDomainHovered } from '../../action/ashreya';
import { Button, Col, Collapse, Divider, Row, Select } from 'antd';
import ShareCourseModal from '../shareCourseModal';
import CardDetailsModal from './cardDetailsModal';
import Lottie from 'react-lottie';
import styled from 'styled-components';
import smartClassroom from "../../assets/AshreyaHome/smartClassroom.json";
import practicalLab from "../../assets/AshreyaHome/practicalLab.json";
import hostel from "../../assets/AshreyaHome/hostel.json";
import mentorship from "../../assets/AshreyaHome/counseling.json";
import smartClassroomIconLottie from "../../assets/AshreyaHome/smart-classroom-icon.json";
import practicalLabIconLottie from "../../assets/AshreyaHome/practical-labs-icon.json";
import hostelIconLottie from "../../assets/AshreyaHome/hostel-icon.json";
import CounselingIconLottie from "../../assets/AshreyaHome/counseling-icon.json";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import MobShiningStarsBanner from './components/mobShiningStarsBanner';
import WebShiningStarsBanner from './components/webShiningStarsBanner';
import WebCoachingBanner from './components/webCoachingBanner';
import WebSchoolIntegratedBanner from './components/webSchoolIntegratedBanner';
import MobSchoolIntegratedBanner from './components/mobSchoolIntegratedBanner';
import MobCoachingBanner from './components/mobCoachingBanner';
import EnquiryForm from './components/EnquiryForm';
import SubDomainCards from './components/subDomainCards';
import MobSubDomainCard from './components/mobSubDomainCard';
import WhyAshreyaCard, { WhyAshreyaData } from './components/whyAshreyaCard';
import WhyAshreyaMobileCard, { WhyAshreyaMobData } from './components/whyAshreyaMobileCard';
import AspiringSection from './components/aspiringSection';
import ScholarshipSection from './components/scholarshipSection';
import FAQSection from './components/faqSection';
import TestimonialsSection, { responsiveTestimonial } from './components/testimonialsSection';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const ButtonGroupWhyAshreya = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide, deviceType }
  } = rest;
  let slideIndex = 5;
  if (deviceType === "mobile") {
    slideIndex = 0;
  }
  return (
    <div className="r-c-c m-t-10 why-ashreya-btn-group">
      {[0, 1, 2].map((indicator) => (
        <div
          key={indicator}
          className="carousel-indicator m-r-10"
          onClick={() => goToSlide(indicator)}
          style={{
            background: currentSlide === indicator ? "#FFA91F" : "#FFF6E9"
          }}
        ></div>
      ))}
    </div>
  );
};

function AshreyaHome({ setSubDomainHovered, subDomainHovered }) {
  const [stopAutoPlay, setAutoPlay] = useState(false);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [cardIndex, setCardIndex] = useState(-1);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false)

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [])

  let settings2 = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '60px',
  };

  const defaultOptions = (animation,) => {
    return {
      loop: true,
      autoplay: true,
      animationData: animation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      }
    }
  }
  const FacilitiesData = [
    {
      title: "Smart Classrooms",
      icon: require("../../assets/AshreyaHome/facilitiesIcon1.svg").default,
      text: "High-Tech Projectors, Air conditioned, spacious, well-ventilated & furnished.",
      lottie: smartClassroom,
      iconLottie: smartClassroomIconLottie
    },
    {
      title: "Practical Labs",
      icon: require("../../assets/AshreyaHome/facilitiesIcon2.svg").default,
      text: "Standardized labs for all subjects to ensure comprehensive learning.",
      lottie: practicalLab,
      iconLottie: practicalLabIconLottie
    },
    {
      title: "ashreya Hostel",
      icon: require("../../assets/AshreyaHome/facilitiesIcon3.svg").default,
      text: "Indulge in meal-inclusive, homely accommodations in close proximity.",
      lottie: hostel,
      iconLottie: hostelIconLottie
    },
    {
      title: "Personal Mentorship & Career Counseling",
      icon: require("../../assets/AshreyaHome/facilitiesIcon4.svg").default,
      text: "One faculty assigned for personal mentoring of every student. We also provide expert guidance on important career choices.",
      lottie: mentorship,
      iconLottie: CounselingIconLottie
    },
  ]

  const BannerButtonGroup = ({ next, previous, goToSlide, hidden, ...rest }) => {
    const {
      carouselState: { currentSlide, deviceType }
    } = rest;
    const carouselIndicators = [2, 3];
    return (
      <div className={`r-c-c ${!hidden ? "carousel-dots" : "m-t-10"}`}>
        {carouselIndicators.map((indicator) => (
          <div
            key={indicator}
            className="carousel-indicator m-r-10 cursor-pointer"
            onClick={() => {
              goToSlide(indicator);
            }}
            style={{
              background: currentSlide === indicator ? "#FFA91F" : "#FFF6E9"
            }}
          ></div>
        ))}
      </div>
    );
  };

  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const [hoveredCardIndex, setHoveredCardIndex] = useState(-1);

  const handleMouseEnter = (index) => {
    setHoveredCardIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredCardIndex(-1);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveCardIndex((prevIndex) => (prevIndex + 1) % FacilitiesData.length);
    }, 3000);

    return () => clearInterval(timer);
  }, []);

  const [values, setValues] = useState(0)
  const [reset, setReset] = useState(false);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [])

  const length = FacilitiesData.length;
  const setCustomValue = (value) => {
    setValues(value);
  }

  useEffect(() => {
    if (!reset) {
      const mount = setInterval(() => {
        setValues((prev) => {
          if (prev == length - 1) {
            return prev - (length - 1);
          }
          else {
            return prev + 1;
          }
        })
      }, 5000)

      return () => clearInterval(mount);
    }
  }, [reset])

  return (
    <div onMouseMove={() => setSubDomainHovered(false)} className={`${subDomainHovered ? "bg-image" : ""}`}>
      <div className={`home-carousel-container`}
        style={{ marginTop: 80 }}
        onMouseMove={(e) => { setAutoPlay(true) }}
        onMouseLeave={() => { setAutoPlay(false) }}
      >
        <Carousel
          dotListClass='carousel-dots'
          responsive={responsive}
          infinite
          customTransition="transform 500ms ease-in-out"
          transitionDuration={500}
          arrows={false}
          showDots={false}
          // autoPlay={stopAutoPlay ? false : true}
          autoPlay={false}
          autoPlaySpeed={4000}
          pauseOnHover={false}
          renderButtonGroupOutside={true}
          customButtonGroup={<BannerButtonGroup />}
        >
          <WebShiningStarsBanner />
          <WebCoachingBanner
            isShareModalVisible={isShareModalVisible}
            setIsShareModalVisible={setIsShareModalVisible}
            setShareUrl={setShareUrl} />
        </Carousel>
      </div>
      <div className="mob-home-carousel-container"
        style={{ marginTop: 40 }}
        onMouseMove={(e) => { setAutoPlay(true) }}
        onMouseLeave={() => { setAutoPlay(false) }}>
        <Carousel
          responsive={responsive}
          infinite
          customTransition="transform 500ms ease-in-out"
          transitionDuration={500}
          arrows={false}
          showDots={false}
          autoPlay={stopAutoPlay ? false : true}
          autoPlaySpeed={6000}
          pauseOnHover={false}
          renderButtonGroupOutside={true}
          customButtonGroup={<BannerButtonGroup />}
        >
          <MobShiningStarsBanner />
          <MobCoachingBanner
            isShareModalVisible={isShareModalVisible}
            setIsShareModalVisible={setIsShareModalVisible}
            setShareUrl={setShareUrl} />
        </Carousel>
      </div>
      <EnquiryForm />
      <div className=' m-t-50 parent-subdomain' style={{ width: "100%" }}>
        <SubDomainCards
          text1="ashreya Tutorials"
          text2="A premium tuition centre for students of X standards"
          index={1} />
        {/* <SubDomainCards
          text1="ashreya Academy"
          text2="Worried about board exams and entrance? Let’s prepare the smart way! (11th & 12th)."
          index={2} /> */}
        <SubDomainCards
          text1="ashreya Coaching"
          text2="Prepare for the entrance exams (JEE/NEET) with our experienced teachers."
          index={3} />
      </div>
      <div className='mobile-parent-subdomain'>
        <Slider {...settings2} style={{ zIndex: 100 }} className='childDomainSlider'>
          <MobSubDomainCard
            text1="ashreya Tutorials"
            text2="A premium tuition centre for students of X standards"
            index={1} />
          {/* <MobSubDomainCard
            text1="ashreya Academy"
            text2="Worried about board exams and entrance? Let’s prepare the smart way! (11th & 12th)."
            index={2} /> */}
          <MobSubDomainCard
            text1="ashreya Coaching"
            text2="Prepare for the entrance exams (JEE/NEET) with our experienced teachers."
            index={3} />
        </Slider>
      </div>
      <center className='text-50 color-sec-black font-bold m-t-100 why-ashreya-margin mobile-font'>
        Why choose ashreya?
      </center>
      <div className="p-h-120 why-ashreya-parent" style={{ width: "100%" }}>
        <WhyAshreyaCard key={0} data={WhyAshreyaData[0]} />
        <WhyAshreyaCard key={1} data={WhyAshreyaData[1]} />
        <WhyAshreyaCard key={2} data={WhyAshreyaData[2]} />
        <WhyAshreyaCard key={3} data={WhyAshreyaData[3]} />
        <WhyAshreyaCard key={4} data={WhyAshreyaData[4]} />
        <WhyAshreyaCard key={5} data={WhyAshreyaData[5]} />
      </div>
      <div className='p-h-20' style={{ width: "100%", marginTop: -20 }}>
        <Carousel
          responsive={responsiveTestimonial}
          arrows={false}
          renderButtonGroupOutside={true}
          customButtonGroup={<ButtonGroupWhyAshreya />}
        >
          <div>
            <WhyAshreyaMobileCard key={0} data={WhyAshreyaMobData[0]} />
            <WhyAshreyaMobileCard key={1} data={WhyAshreyaMobData[1]} />
          </div>
          <div>
            <WhyAshreyaMobileCard key={2} data={WhyAshreyaMobData[2]} />
            <WhyAshreyaMobileCard key={3} data={WhyAshreyaMobData[3]} />
          </div>
          <div>
            <WhyAshreyaMobileCard key={4} data={WhyAshreyaMobData[4]} />
            <WhyAshreyaMobileCard key={5} data={WhyAshreyaMobData[5]} />
          </div>
        </Carousel>
      </div>
      <center className='text-50 color-sec-black font-bold m-t-50 facilities-margin mobile-font p-h-20'>
        Facilities offered by ashreya
      </center>
      <div className='facilities-parent'>
        <div className='facilities-child1'>
          {FacilitiesData.map((data, index) => (
            <div
              key={index}
              className={`facilities-card zoom cursor-pointer ${index === hoveredCardIndex ? 'zoom' : ''}`}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <div className='facilities-img'>
                <img alt="facilities" className='facilities-icon' src={data.icon} loading="lazy" />
              </div>
              <div className='r-c-c facilities-icon-lottie'>
                <Lottie options={defaultOptions(FacilitiesData[hoveredCardIndex]?.iconLottie)} isClickToPauseDisabled={true} height={60} width={60} />
              </div>
              <div className='m-l-20'>
                <div className='text-20 color-primary font-bold mob-title-font' style={{ width: "80%", lineHeight: 1.5 }}>{data.title}</div>
                <div className='text-sm m-t-10 mob-desc-font' style={{ color: "#5B5462", }}>{data.text}</div>
              </div>
            </div>
          ))}
        </div>
        <div className='facilities-child2'>
          {hoveredCardIndex !== -1 ? (
            <Lottie className='gif-style' options={defaultOptions(FacilitiesData[hoveredCardIndex]?.lottie)} isClickToPauseDisabled={true} height={330} width={330} />
          ) : (
            <Lottie className='gif-style' options={defaultOptions(FacilitiesData[activeCardIndex]?.lottie)} isClickToPauseDisabled={true} height={330} width={330} />
          )}
        </div>
      </div>
      <div className='facilities-mob-parent m-t-40'>
        <ValuesCarousal>
          <Row style={{ height: 500 }}>
            <Col
              xs={{ span: 24, order: 2 }}
              sm={{ span: 24, order: 2 }}
              md={{ span: 12, order: 1 }}
              lg={{ span: 12, order: 1 }}
              xl={{ span: 12, order: 1 }}
              xxl={{ span: 12, order: 1 }}
              className='description'
            >
              <div className='carousal' onMouseEnter={() => { setCustomValue(values); setReset(!reset) }}
                onMouseLeave={() => setReset(!reset)}>
                <span className='color-primary font-bold text-md'>{FacilitiesData[values].title}</span>
                <p className='color-light-black text-sm'>{FacilitiesData[values].text}</p>
              </div>
            </Col>
            <Col
              xs={{ span: 24, order: 2 }}
              sm={{ span: 24, order: 2 }}
              md={{ span: 12, order: 2 }}
              lg={{ span: 12, order: 2 }}
              xl={{ span: 12, order: 2 }}
              xxl={{ span: 12, order: 2 }}
              className='image-container'
            >
              <Lottie className='carousal-image' options={defaultOptions(FacilitiesData[values]?.lottie)} isClickToPauseDisabled={true} height={350} width={350} />
            </Col>
          </Row>
          <Row className='arrow-button '>
            <div className='lines'>
              {FacilitiesData.map((ele, index) => {
                return <div className={values === index ? 'blueline' : 'greyline'} onClick={() => { setCustomValue(index); setReset(!reset) }}
                  onMouseLeave={() => setReset(!reset)}></div>
              })}
            </div>
          </Row>
        </ValuesCarousal>
      </div>
      <div className='text-sm m-t-10 font-bold learn-more-about' style={{ color: "#0066FF", display: "flex", justifyContent: "center", width: "100%" }}><Link to="/about"><div>LEARN MORE ABOUT ashreya</div></Link></div>
      <AspiringSection />
      <ScholarshipSection />
      <center className='text-50 color-sec-black font-bold m-t-70 m-b-70 faq-bottom-margin mobile-font'>FAQ'S</center>
      <FAQSection />
      <center className='text-50 color-sec-black font-bold m-t-100 m-b-70 test-margin mobile-font'>Testimonials</center>
      <TestimonialsSection />
      {isShareModalVisible &&
        <ShareCourseModal
          isShareModalVisible={isShareModalVisible}
          setIsShareModalVisible={setIsShareModalVisible}
          shareUrl={shareUrl} />
      }
      {isDetailsModalVisible &&
        <CardDetailsModal
          isDetailsModalVisible={isDetailsModalVisible}
          setIsDetailsModalVisible={setIsDetailsModalVisible}
          cardIndex={cardIndex}
          setIsShareModalVisible={setIsShareModalVisible}
          setShareUrl={setShareUrl}
        />
      }
    </div>
  )
}
const mapStateToProps = (state) => {
  const { subDomainHovered } = state.ashreya;
  return {
    subDomainHovered
  }
};
const mapDispatchToProps = (dispatch) => ({
  setSubDomainHovered: (val) => dispatch(setSubDomainHovered(val))
});
export default connect(mapStateToProps, mapDispatchToProps)(AshreyaHome);

const ValuesCarousal = styled.div`
        text-align:left;
        @media screen and (max-width: 770px){
          padding:0px 20px;
        .lines{
          display:flex;
        flex-direction:row;
        align-items:center;
        gap:10px;    
      }
        .carousal{
          margin - top: 50px
      }
        .arrow-button{
          padding - bottom: 50px;
        font-size: calc(1vw + 8px);
        font-weight: 600;
        font-family: 'Sofia Sans', sans-serif;
        margin-top:20px;     
      }
        .blueline{
          width:60px;
        height:4px;
        background-color:#9671BC;
        border-radius:3px;
        cursor:pointer;
      }
        .greyline{
          width:10px;
        height:4px;
        background-color:#9D9D9D;
        border-radius:3px;
        cursor:pointer;
      }
        .carousal-image{
          width: 80%;
        height: 250px;
        object-fit: fill;
    }
        .image-container{
          display: flex;
        justify-content: center;
        align-items: center;
        width:50%;
        height:50%;
  }
    }
        `   