import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import { Font39, Font19, Font23, Font15 } from '../../../components/Typography';
import './styles.css';

function Banner() {
    const bannerRef = useRef(null);
    const isInView = useInView(bannerRef, { once: false });

    const BannerCard = () => (
        <div className="features-container">
            <div className="feature-item">
                <img src={require("../../../assets/NewAshreyaHome/feature-card-icon1.svg").default} alt="Experienced IITian mentors" />
                <div className="feature-text">
                    <Font15 color='#1C67CD'>Experienced</Font15>
                    <Font15 color='#191919' margin={'2px 0px 0px 0px'}>IITian mentors</Font15>
                </div>
            </div>
            <div className="vertical-line"></div>
            <div className="feature-item">
                <img src={require("../../../assets/NewAshreyaHome/feature-card-icon2.svg").default} alt="Digital library" />
                <div className="feature-text">
                    <Font15 color='#499F09'>Digital</Font15>
                    <Font15 color='#191919' margin={'2px 0px 0px 0px'}>library</Font15>
                </div>
            </div>
            <div className="vertical-line"></div>
            <div className="feature-item">
                <img src={require("../../../assets/NewAshreyaHome/feature-card-icon3.svg").default} alt="24/7 support" />
                <div className="feature-text">
                    <Font15 color='#CF0058'>24/7</Font15>
                    <Font15 color='#191919' margin={'2px 0px 0px 0px'}>support</Font15>
                </div>
            </div>
        </div>
    );

    return (
        <motion.div ref={bannerRef} className="banner-container">
            <motion.div
                initial={{ scale: 0.8, opacity: 0 }}
                animate={isInView ? { scale: 1, opacity: 1 } : { scale: 0.8, opacity: 0 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
            >
                <motion.img
                    src={require("../../../assets/NewAshreyaHome/webBanner1-bg-image.svg").default}
                    loading="lazy"
                    alt="web-banner"
                    className="banner-image web-banner1-bg-img"
                />
                <motion.img
                    src={require("../../../assets/NewAshreyaHome/mobBanner1-bg-image.svg").default}
                    loading="lazy"
                    alt="mob-banner"
                    className="banner-image mob-banner1-bg-img"
                />
                <motion.div
                    className="banner-content"
                    initial={{ opacity: 0 }}
                    animate={isInView ? { opacity: 1 } : { opacity: 0 }}
                    transition={{ duration: 0.5, ease: "easeInOut", delay: 0.3 }}
                >
                    <Font39 color='#FFFFFF'>Aspiring Education, Inspiring Lives!</Font39>
                    <div className='banner-title2-margin'>
                        <Font23 color='#FFFFFF'>Experience the new way of digital learning.</Font23>
                    </div>
                    <div className="banner-buttons">
                        <BannerButton title="COACHING" subtitle="NEET & JEE" />
                        <BannerButton title="TUTORIALS" subtitle="Class X boards" />
                    </div>
                </motion.div>
            </motion.div>
            <motion.div
                className='banner-centered-box'
                initial={{ opacity: 0 }}
                animate={isInView ? { opacity: 1 } : { opacity: 0 }}
                transition={{ duration: 0.5, delay: 0.5, ease: "easeInOut" }}
            >
                <BannerCard />
            </motion.div>
        </motion.div>
    );
}

function BannerButton({ title, subtitle }) {
    return (
        <button className="banner-button" onClick={() => {
            if (title === "COACHING") {
                window.location.assign('https://coaching.ashreya.com/')
            } else if (title === "TUTORIALS") {
                window.location.assign('https://tutorials.ashreya.com/')
            }
        }}>
            <div className='glare'></div>
            <Font19 color='#000000' bold margin={"0px 0px 5px 0px"}>{title}</Font19>
            <Font15 color='#000000'>{subtitle}</Font15>
        </button>
    );
}

export default Banner;
