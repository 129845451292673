import React from 'react'

export const SchoolIntegratedBannerCardObj = [{
    image: require("../../../assets/AshreyaHome/schoolIntCard1Img.png"),
    text: "Boards + JEE",
    amount: "₹ 1,30,000",
},
{
    image: require("../../../assets/AshreyaHome/schoolIntCard2Img.png"),
    text: "Boards + NEET",
    amount: "₹ 1,30,000",
},
]

export const SchoolIntegratedCard = ({ data, index }) => {
    return (
        <div className='banner3-card m-r-20 m-t-20 cursor-pointer' onClick={() => {
            if (index == 1) {
                window.location.assign("https://academy.ashreya.com/courses/engineering")
            } else {
                window.location.assign("https://academy.ashreya.com/courses/medical")
            }
        }}>
            <div className='r-sb'>
                <div>
                    <div className='text-24 color-sec-black font-bold bannercard-title-font'>{data?.text}</div>
                    <div className='text-sm color-primary m-t-10'>{data?.amount}<span className='text-xsm m-l-5' style={{ textDecoration: "line-through" }}>{"₹ 1,80,000"}</span></div>
                </div>
                <img alt='school-integrated-icon' loading='lazy' src={data?.image} style={{ height: 35, width: 35, marginTop: 25 }} />
            </div>
        </div>
    )
}

function WebSchoolIntegratedBanner() {
    return (
        <div className='r-sb m-t-20 ashreya-banner-wrapper cursor-pointer'>
            <div style={{ width: "70%" }}>
                <div className='text-24 color-dark-black'>Register to our</div>
                <div className='font-bold text-xlg color-primary m-t-20'>School Integrated Program [2024-2026]</div>
                <div className='text-20 color-light-black m-t-20' style={{ lineHeight: 1.6 }}>ashreya Academy brings to you specially curated programmes to help you prepare for your entrance exams, enabling you to meet your aspirations without compromising your board preparations.</div>
                <div className='text-20 m-t-30' style={{ color: "#008445" }}></div>
                <div className='display-flex flex-wrap'>
                    <SchoolIntegratedCard data={SchoolIntegratedBannerCardObj[0]} index={1} />
                    <SchoolIntegratedCard data={SchoolIntegratedBannerCardObj[1]} index={2} />
                    <div className='early-bird-banner3-position'><img alt='earlyBirdImage1' loading='lazy' src={require("../../../assets/AshreyaHome/earlybird.svg").default} style={{ width: "90%", marginTop: 20 }} /></div>
                </div>
                <div className='text-xsm font-bold m-t-40' style={{ color: "#B81B1B" }}>ADMISSIONS OPEN</div>
            </div>
            <div className='r-c-c' style={{ width: "30%", display: "flex", justifyContent: "flex-end" }}>
                <img alt='ashreya-banner3-img' loading='lazy' className='ashreya-banner3-img-size' src={require("../../../assets/AshreyaHome/schoolIntegratedBannerImg.png")} />
            </div>
        </div>
    )
}

export default WebSchoolIntegratedBanner