import React from "react";

const Container = ({ children }) => (
  <div
    style={{
      width: "100%",
      height: "100%",
      margin: '0 auto'
    }}
  >
    {children}
  </div>
);

export default Container;
