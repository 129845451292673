import { Dropdown } from 'antd';
import React from 'react'
import './styles.css'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function CustomFooter() {
    const history = useHistory()
    const menu = (
        <img alt="googlestoreQr" loading="lazy"
            src={require("../../assets/Footer/googlestoreQr.png")} style={{ height: 130, width: 130, border: "none" }} />
    );
    const menu1 = (
        <img alt="appstoreQr" loading="lazy"
            src={require("../../assets/Footer/appstoreQr.png")} style={{ height: 130, width: 130, border: "none" }} />
    )
    return (
        <div style={{ zIndex: 3000 }} className='position-relative'>
            <div className='web-footer-main' style={{ width: "100%", backgroundColor: "#FBF8FF", height: "fit-content" }}>
                <div className='r-sb r-c-c web-footer-parent'>
                    <div className='display-flex'>
                        <div className="text-sm color-sec-black font-600 m-r-30 r-c-c download-div" > Download Our App</div>
                        <Dropdown
                            overlay={menu}>
                            <a
                                href="https://play.google.com/store/apps/details?id=com.ashreya.aulas&hl=en-IN"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    className="m-r-30 qr-width"
                                    style={{ height: 80 }}
                                    src={require("../../assets/Footer/googlePlay.svg").default}
                                    alt="googlePlay"
                                    loading="lazy"
                                />
                            </a>
                        </Dropdown>
                        <Dropdown
                            overlay={menu1}>
                            <a
                                href="https://apps.apple.com/us/app/aulas/id1564724183?platform=iphone"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    className="qr-width"
                                    style={{ height: 80, marginRight: 0 }}
                                    src={require("../../assets/Footer/appStore.svg").default}
                                    alt="appStore"
                                    loading="lazy"
                                />
                            </a>
                        </Dropdown>
                    </div>
                    <div className='display-flex'>
                        <div className="text-sm color-sec-black m-r-20 register-div"> Register / Login into web portal</div>
                        <div className="text-sm">
                            <a
                                href="https://ashreya.aulas.in"
                                target="_blank"
                                rel="Vectors Academy Web Portal"
                            >ashreya Web Portal</a>
                        </div>
                    </div>
                </div>
                <div className='web-footer-parent'><div style={{ borderBottom: "1px solid #BDBDBD", width: "100%" }}></div></div>
                <div className='r-sb m-t-20 web-footer-parent' style={{ width: "100%" }}>
                    <div style={{ textAlign: "left", width: "fit-content" }}>
                        <Link to="/"><div className='text-md color-sec-black font-bold cursor-pointer' onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }}>Home</div></Link>
                        <Link to="/"><div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.scrollTo({ top: 850, left: 0, behavior: "smooth" }) }}>Why ashreya?</div></Link>
                        <Link to="/"><div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.scrollTo({ top: 1600, left: 0, behavior: "smooth" }) }}>Facilities</div></Link>
                        <Link to='/scholarship'><div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => {
                            if (window.location.pathname == "/scholarship") {
                                window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                            }
                        }}>Scholarship</div></Link>
                        <Link to="/"><div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.scrollTo({ top: 3450, left: 0, behavior: "smooth" }) }}>FAQ’s</div></Link>
                        <Link to="/"><div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.scrollTo({ top: 4800, left: 0, behavior: "smooth" }) }}>Testimonials</div></Link>
                        <Link to='/about'><div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => {
                            if (window.location.pathname == "/about") {
                                window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                            }
                        }}>About</div></Link>
                    </div>
                    <div style={{ textAlign: "left", width: "fit-content" }}>
                        <div className='text-md color-sec-black font-bold cursor-pointer' onClick={() => { window.location.assign('https://tutorials.ashreya.com/') }}>Tutorials</div>
                        <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://tutorials.ashreya.com/') }}>Home</div>
                        {/* <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://tutorials.ashreya.com/resources/cbse') }}>Resources (CBSE)</div>
                        <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://tutorials.ashreya.com/resources/seba') }}>Resources (ASSEB)</div>
                        <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://tutorials.ashreya.com/resources/announcements') }}>Announcements</div> */}
                        {/* <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://tutorials.ashreya.com/facilities') }}>Facilities</div> */}
                        <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://tutorials.ashreya.com/about') }}>About</div>
                        <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://tutorials.ashreya.com/contact') }}>Contact</div>
                    </div>
                    {/* <div style={{ textAlign: "left", width: "fit-content" }}> */}
                        {/* <div className='text-md color-sec-black font-bold cursor-pointer' onClick={() => { window.location.assign('https://academy.ashreya.com/') }}>Academy</div>
                        <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://academy.ashreya.com/courses/medical') }}>Course (Boards + NEET)</div>
                        <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://academy.ashreya.com/courses/engineering') }}>Course (Boards + JEE)</div> */}
                        {/* <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://academy.ashreya.com/resources/ncert') }}>Resources (NCERT)</div>
                        <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://academy.ashreya.com/resources/ahsec') }}>Resources (AHSEC)</div>
                        <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://academy.ashreya.com/resources/competative-exams') }}>Resources (Competitive Exams)</div>
                        <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://academy.ashreya.com/resources/announcements') }}>Announcements</div> */}
                        {/* <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://academy.ashreya.com/tests/art') }}>Test ART</div> */}
                        {/* <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://academy.ashreya.com/tests/asat') }}>ASAT Junior</div>
                        <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://academy.ashreya.com/facilities') }}>Facilities</div>
                        <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://academy.ashreya.com/contact') }}>Contact</div> */}
                    {/* </div> */}
                    <div style={{ textAlign: "left", width: "fit-content" }}>
                        <div className='text-md color-sec-black font-bold cursor-pointer' onClick={() => { window.location.assign('https://coaching.ashreya.com/') }}>Coaching</div>
                        <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://coaching.ashreya.com/') }}>Home </div>
                        <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://ashreya.aulas.in/') }}>Courses </div>
                        {/* <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://coaching.ashreya.com/medical-capsule-course') }}>Course (Capsule NEET)</div> */}
                        {/* <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://coaching.ashreya.com/engineering-target-batch') }}>Course (Target JEE)</div> */}
                        {/* <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://coaching.ashreya.com/engineering-capsule-course') }}>Course (Capsule JEE)</div> */}
                        {/* <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://coaching.ashreya.com/resources/ncert') }}>Resources (NCERT)</div>
                        <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://coaching.ashreya.com/resources/ahsec') }}>Resources (AHSEC)</div>
                        <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://coaching.ashreya.com/resources/competative-exams') }}>Resources (Competitive Exams)</div>
                        <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://coaching.ashreya.com/resources/announcements') }}>Announcements</div> */}
                        {/* <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://coaching.ashreya.com/tests/art') }}>Test ART</div> */}
                        {/* <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://coaching.ashreya.com/tests/asat') }}> ASAT Senior</div> */}
                        <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://coaching.ashreya.com/facilities') }}>Facilities</div>
                        <div className='text-xsm color-sec-black m-t-10 cursor-pointer' onClick={() => { window.location.assign('https://coaching.ashreya.com/contact') }}>Contact</div>
                    </div>
                </div>
                <div className='web-footer-parent m-t-40' style={{ width: "40%" }}>
                    <img src={require("../../assets/ashreya-primary-logo.svg").default} style={{ width: 130 }} alt="primaryLogo" loading="lazy"/>
                    <div className='text-xsm color-sec-black m-t-10' style={{ lineHeight: 1.5 }}>2nd & 3rd Floor, 67 B, Fatick Chandra Rd, Latasil, Uzan Bazar, Guwahati, Assam 781001</div>
                </div>
                <div className='web-footer-child m-t-20 p-h-120 p-t-20 p-b-20 r-sb' style={{ height: "fit-content", width: "100%", backgroundColor: "#623695" }}>
                    <div className='color-white'>Copyright @ Vectors Education LLP. All rights reserved.</div>
                    <div className='color-white r-sb' style={{ width:"25%"}}>
                        <div className='cursor-pointer' onClick={()=>{history.push("/terms-conditions")}}>Terms & Conditions</div>
                        <div className='cursor-pointer' onClick={()=>{history.push("/privacy-policy")}}>Privacy Policy</div>
                    </div>
                </div>
            </div>
            <div className='m-t-50 mobile-footer-parent' style={{ width: "100%", backgroundColor: "#FBF8FF", height: "fit-content", paddingTop: 20 }}>
                <div className="text-sm color-sec-black font-600 p-h-20" > Download Mobile App</div>
                <div className='p-h-20'>
                    <div>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.ashreya.aulas&hl=en-IN"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                className="m-r-20"
                                style={{ width: 130, height: 80, marginRight: 30 }}
                                src={require("../../assets/Footer/googlePlay.svg").default}
                                alt="googlePlay"
                                loading="lazy"
                            />
                        </a>
                        <a
                            href="https://apps.apple.com/us/app/aulas/id1564724183?platform=iphone"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                className="m-r-20"
                                style={{ width: 130, height: 80 }}
                                src={require("../../assets/Footer/appStore.svg").default}
                                alt="appStore"
                                loading="lazy"
                            />
                        </a>
                    </div>
                    <div className="text-sm color-sec-balck"> Register / Login into web portal</div>
                    <div className="text-sm m-t-20">
                        <a
                            href=" https://ashreya.aulas.in"
                            target="_blank"
                            rel="Vectors Academy Web Portal"
                        >ashreya Web Portal</a>
                    </div>
                </div>
                <div className='p-h-10 m-t-20'><div style={{ borderBottom: "1px solid #BDBDBD", width: "100%" }}></div></div>
                <div className='p-h-20 r-sb m-t-20' style={{ width: "100%" }}>
                    <div style={{ textAlign: "left", width: "fit-content" }}>
                        <Link to="/"><div className='text-sm color-sec-black cursor-pointer' onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }}>Home</div></Link>
                        <div className='text-sm color-sec-black cursor-pointer m-t-10' onClick={() => { window.location.assign('https://tutorials.ashreya.com/') }}>Tutorials</div>
                        {/* <div className='text-sm color-sec-black cursor-pointer m-t-10' onClick={() => { window.location.assign('https://academy.ashreya.com/') }}>Academy</div> */}
                        <div className='text-sm color-sec-black cursor-pointer m-t-10' onClick={() => { window.location.assign('https://coaching.ashreya.com/') }}>Coaching</div>
                        <Link to="/terms-conditions"><div className='text-sm color-sec-black cursor-pointer m-t-10' onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }}>Terms & Conditions</div></Link>
                        <Link to="/privacy-policy"><div className='text-sm color-sec-black cursor-pointer m-t-10' onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }}>Privacy Policy</div></Link>
                    </div>
                </div>
                <div className='m-t-20' style={{ padding: "10px 20px 10px 20px", height: "fit-content", width: "100%", backgroundColor: "#623695" }}>
                    <div className='color-white text-xxs'>Copyright @ Vectors Education LLP. All rights reserved.</div>
                </div>
            </div>
        </div>
    )
}

export default CustomFooter