import React, { useState } from 'react';
import './styles.css';
import { Font21, Heading } from '../../../components/Typography';
import VideoPlayer from './videoPlayer';

function MoreAboutAshreya() {
    return (
        <div className='more-about-ashreya-container responsive-padding'>
            <Heading bold>Explore more about ashreya</Heading>
            <div className="more-about-ashreya-video">
                <div className='more-about-ashreya-child'>
                    <VideoPlayer
                        videoUrl="https://www.youtube.com/embed/yYQH9qG5uvg?si=BhuWyMubPJiMQFzU"
                        title={"ashreya Coaching"}
                    />
                    <center><Font21 bold>ashreya Coaching</Font21></center>
                </div>
                <div className='more-about-ashreya-child'>
                    <VideoPlayer
                        videoUrl="https://www.youtube.com/embed/yQz5DvQtdrY?si=KWgs-kWV8r_2UqjA"
                        title={"ashreya Tutorials"}
                    />
                    <center><Font21 bold>ashreya Tutorials</Font21></center>
                </div>
            </div>
        </div>
    );
}

export default MoreAboutAshreya;
