import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Layout } from "antd";
import { connect } from 'react-redux';
import Sidebar from "react-sidebar";
import AshreyaHome from "../pages/AshreyaHome";
import TopBar from "../components/TopBar";
import Container from "../components/Container";
import CustomFooter from "../components/Footer";
import CustomSideBar from "../components/CustomSideBar";
import Scholarship from "../pages/Scholarship";
import About from "../pages/AshreyaAbout";
import "./styles.css"
import TermsConditions from "../pages/termsConditions";
import PrivacyPolicy from "../pages/privacyPolicy";
import AsatRegister from "../pages/asatRegister";
import TopBarNew from "../components/TopBarNew";
import NewAshreyaHome from "../pages/NewAshreyaHome";
import NewCustomSideBar from "../components/NewCustomSideBar";
import BlogsDashboard from "../pages/NewAshreyaHome/components/blogsDashboard";
import { Blog1, Blog2, Blog3, Blog4, Blog5, Blog6, Blog7, Blog8, Blog9 } from "../pages/NewAshreyaHome/components/singleBlogData";
import SideMenuPage from "../pages/NewAshreyaHome/components/sideMenuPage";

const { Header, Footer, Content, Sider } = Layout;

class Root extends React.Component {
  render() {
    const { sideBar } = this.props
    return (
      <div style={{ overflow: sideBar ? 'hidden' : 'unset', height: sideBar ? '100%' : 'auto' }}>
        <Router>
          <Layout className="layout-body" style={{ zIndex: '0' }}>
            <Sidebar
              sidebar={<NewCustomSideBar />}
              open={sideBar}
              styles={{ sidebar: { background: "white", left: 0, right: 0 } }}
              pullRight={false}
            >
              <div></div>
            </Sidebar>
            <Layout className="layout-body" style={{ zIndex: '0' }}>
              <Header style={{ background: '#623695', height: 'auto' }}>
                <Container>
                  <TopBarNew />
                </Container>
              </Header>
              <Content
              >
                <div style={{ height: '100%', width: '100%' }}>
                  <Switch>
                    <Route exact path="/" component={NewAshreyaHome} />
                    <Route exact path="/home" component={AshreyaHome} />
                    <Route exact path='/blogs' component={BlogsDashboard} />
                    <Route exact path='/blog/the-world-through-a-microscopic-lens' component={Blog7} />
                    <Route exact path='/blog/my-chemistry-chronical' component={Blog8} />
                    <Route exact path='/blog/empowering-students-for-sucess' component={Blog9} />
                    <Route exact path='/blog/glimpse-into-our-state-of-the-art-Facilities' component={Blog4} />
                    <Route exact path='/blog/ashreyas-educational-impact-across-assam' component={Blog5} />
                    <Route exact path='/blog/the-inspiring-journey-of-bitopan-kalita' component={Blog1} />
                    <Route exact path='/blog/tips-and-tricks-for-ashreya-academy-students' component={Blog3} />
                    <Route exact path='/blog/a-journey-from-challenges-to-success-in-jee-mains-2023' component={Blog2} />
                    <Route exact path='/blog/a-journey-through-physics-with-mayank-singh' component={Blog6} />
                    <Route exact path='/menu' component={SideMenuPage} />
                    <Route exact path="/scholarship" component={Scholarship} />
                    <Route exact path="/about" component={About} />
                    <Route exact path="/terms-conditions" component={TermsConditions} />
                    <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                    <Route exact path="/asat-register" component={AsatRegister} />
                  </Switch>
                </div>
              </Content>
              <div style={{ zIndex: 2000000 }}>
                <CustomFooter />
              </div>
            </Layout>
          </Layout>
        </Router>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const {
    sideBar
  } = state.ashreya;
  return {
    sideBar
  };
};

export default connect(mapStateToProps)(Root);
