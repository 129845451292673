import React from 'react'
import { connect } from 'react-redux';
import { setSideBar } from '../../action/ashreya';
import { CloseCircleFilled } from '@ant-design/icons';

function NewCustomSideBar({ setSideBar }) {
    const handleClose = () => {
        setSideBar(false)
    };
    return (
        <div className="container" >
            <div className="content">
                <div className='c-c-c' style={{ padding: "20px 20px", position: 'relative' }}>
                    <CloseCircleFilled
                        onClick={handleClose}
                        style={{ position: 'absolute', top: 15, right: 15, cursor: 'pointer', fontSize: 24, color: "gray" }}
                    />
                    <div className='c-c-c' onClick={() => window.open("https://ashreya.aulas.in")}>
                        <img src={require("../../assets/primary-v-logo.svg").default} alt="primary-v-logo" loading='lazy' style={{ width: 50, height: 50 }} />
                        <div className='m-b-20' style={{ fontSize: 18, color: "#636363" }}>Explore</div>
                    </div>
                    <img src={require("../../assets/sidebar-image1.svg").default} alt="sidebar-image1" loading='lazy' onClick={() => { setSideBar(false) }} style={{ width: "100%" }} />
                    <img src={require("../../assets/sidebar-image2.svg").default} alt="sidebar-image2" loading='lazy' onClick={() => { window.location.assign('https://coaching.ashreya.com/') }} style={{ marginLeft: "20px", marginRight: 20, width: "100%" }} />
                    <img src={require("../../assets/sidebar-image3.svg").default} alt="sidebar-image3" loading='lazy' onClick={() => { window.location.assign('https://tutorials.ashreya.com/') }} style={{ marginLeft: "20px", marginRight: 20, width: "100%" }} />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { sideBar } = state.ashreya;
    return { sideBar };
};

const mapDispatchToProps = (dispatch) => ({
    setSideBar: val => dispatch(setSideBar(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewCustomSideBar)