import React, { useState, useEffect } from "react";
import "./styles.css";
import { Hidden, Visible } from "react-grid-system";
import { setSubDomainHovered } from "../../action/ashreya";
import { connect } from "react-redux";
import Lottie from 'react-lottie';
import innovativeTechnology from "../../assets/About/innovativeTechnology.json";
import connectingEdu from '../../assets/About/connectingEdu.json';
import scholarship from '../../assets/About/scholarship.json';
import Excellence from '../../assets/About/Excellence.json';
import Integrity from '../../assets/About/Integrity.json';
import Innovation from '../../assets/About/Innovation.json';
import Community from '../../assets/About/Community.json';
import Diversity from '../../assets/About/Diversity.json';
import Carousel from "react-multi-carousel";

const responsiveValues = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 767 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1
  }
};
const ButtonGroupValues = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide, deviceType }
  } = rest;
  let slideIndex = 5;
  if (deviceType === "mobile") {
    slideIndex = 0;
  }
  return (
    <div className="r-c-c m-t-10">
      {[0, 1, 2, 3, 4].map((indicator) => (
        <div
          key={indicator}
          className="carousel-indicator m-r-10"
          onClick={() => goToSlide(indicator)}
          style={{
            background: currentSlide === indicator ? "#FFA91F" : "#FFF6E9"
          }}
        ></div>
      ))}
    </div>
  );
};
const About = ({ setSubDomainHovered, subDomainHovered }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [])
  const defaultOptions = (animation,) => {
    return {
      loop: true,
      autoplay: true,
      animationData: animation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      }
    }
  }
  const about_1 = `Welcome to ashreya! A pathbreaking initiative towards revolutionizing not only the current innovation-deficit systems of education, but also a collective mindset that refuses to embrace change in the way that educators and learners engage with each other.`;
  const about_2 = `The ashreya Learning App is our answer to changing times. It offers a dynamic educational experience beyond traditional classrooms, thanks to technology. Over three years, we've developed effective online solutions for various learning scenarios.`
  const about_3 = `Physical presence of teachers and students inside a classroom isn’t always a certainty anymore?`
  const about_4 = 'No problem! Because for ashreya, it is also not the only option anymore.'
  const about_5 = `Where education and innovation unite! With 3 years of experience, we've revolutionized traditional learning with cutting-edge online solutions. Your ultimate destination for modern education awaits!`
  const about_6 = `Since 2018, we've aimed to transform education in the northeastern region, known for its untapped potential. We collaborate with institutions to fill educational gaps, achieving notable results. We're expanding our reach beyond the region to bridge the gap between education and society.`
  const about_7 = `In 2022, we introduced a groundbreaking scholarship program. Unlike typical scholarships, it's a commitment to guide underprivileged students through their Engineering, Medical Sciences, or related courses until graduation. We're making quality education accessible to deserving students.`

  const valueCardData = [
    {
      title: "Excellence",
      lottie: Excellence,
      img: require("../../assets/About/Excellence.svg").default,
      text: "Guiding our teaching, research, and academics, an unwavering commitment to relentless pursuit sets our standards high.",
    },
    {
      title: "Integrity",
      lottie: Integrity,
      img: require("../../assets/About/Integrity.svg").default,
      text: "Honesty and ethics shape all we do, an unbreakable foundation of unwavering principles in action.",
    },
    {
      title: "Innovation",
      lottie: Innovation,
      img: require("../../assets/About/Innovation.svg").default,
      text: "Igniting innovation's flame within, we fuel progress and growth through creativity, leading with fresh ideas.",
    },
    {
      title: "Community",
      lottie: Community,
      img: require("../../assets/About/Community.svg").default,
      text: "As an educational cornerstone, we seamlessly blend with society, sharing knowledge and weaving collective growth.",
    },
    {
      title: "Diversity",
      lottie: Diversity,
      img: require("../../assets/About/Diversity.svg").default,
      text: "Celebrating varied perspectives and backgrounds, our embrace of diversity fosters inclusivity, enriching our shared journey.",
    },
  ];

  const ValueCard = ({ key, data }) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
      <>
        <div className={`valueCard cursor-pointer ${isHovered ? 'zoom' : ''}`} key={key} onMouseMove={() => { setIsHovered(true) }} onMouseLeave={() => { setIsHovered(false) }}>
          <div className="r-ac m-b-10">
            <div>
              {isHovered ? <Lottie className='gif-style' options={defaultOptions(data?.lottie)} isClickToPauseDisabled={true} height={60} width={60} /> : <img src={data?.img} alt="values-icon" style={{ height: 60, width: 60 }} loading="lazy"/>}
            </div>
            <div className="text-md font-bold color-primary m-b-20 m-l-10 m-t-10 mob-title-font">{data.title}</div>
          </div>
          <div style={{ textAlign: "left", color: "#5B5462", lineHeight: 1.6 }} className="text-sm mob-desc-font">
            {data.text}
          </div>
        </div>
      </>
    );
  };
  const ValueCardMob = ({ key, data }) => {
    return (
      <>
        <div className="valueCard" key={key}>
          <div className="r-ac m-b-10">
            <div>
              <Lottie className='gif-style' options={defaultOptions(data?.lottie)} isClickToPauseDisabled={true} height={60} width={60} />
            </div>
            <div className="font-bold color-primary m-b-20 m-l-10 mob-title-font" style={{ marginTop: 15 }}>{data.title}</div>
          </div>
          <div style={{ textAlign: "justify", color: "#5B5462", lineHeight: 1.6 }} className="mob-desc-font">
            {data.text}
          </div>
        </div>
      </>
    );
  };
  return (
    <div>
      <div onMouseMove={() => setSubDomainHovered(false)} className={`${subDomainHovered ? "bg-image" : ""}`}>
        <div className="ashreya-about-parent">
          <div className="text-40 color-sec-black text-center font-bold mobile-font" >
            Aspiring Education, Inspiring Lives!
          </div>
          <center className="m-t-30 text-sm color-light-black mob-desc-font" style={{ width: "100%", lineHeight: 1.6 }}>{about_1}</center>
        </div>
        <div className="innovative-tech-parent">
          <div className="innovative-tech-child1">
            <div className="text-md color-sec-black font-bold ">
              Innovative Learning with Technology
            </div>
            <div className="m-t-30 text-sm color-light-black" style={{ textAlign: "justify", lineHeight: 1.6 }}>{about_2}</div>
          </div>
          <div className="innovative-tech-child2">
            <Lottie className='gif-style' options={defaultOptions(innovativeTechnology)} isClickToPauseDisabled={true} height={300} width={300} />
          </div>
        </div>
        <div className="mob-innovative-tech-parent m-t-30">
          <center className="text-md color-sec-black font-bold m-b-10">
            Innovative Learning with Technology
          </center>
          <Lottie className='gif-style' options={defaultOptions(innovativeTechnology)} isClickToPauseDisabled={true} height={230} width={230} />
          <div className="m-t-10 color-light-black mob-desc-font" style={{ textAlign: "justify", lineHeight: 1.6 }}>{about_2}</div>
        </div>
        <div className="learning-app-parent">
          <div className="r-c-c">
            <img alt="LearningApp" src={require("../../assets/About/LearningApp.jpg")} className="learning-app-width" loading="lazy"/>
          </div>
          <div className="learning-app-child2 text-align-right-div">
            <div className="text-md color-sec-black font-bold">
              ashreya Learning App
            </div>
            <div className="m-t-30 text-sm color-light-black" style={{ lineHeight: 1.6 }}>{about_3}</div>
            <div className="text-sm color-light-black" style={{ lineHeight: 1.6 }}>{about_4}</div>
            <div className="m-t-30 text-sm color-light-black" style={{ lineHeight: 1.6 }}>{about_5}</div>
          </div>
        </div>
        <div className="mob-learning-app-parent m-t-30">
          <center className="text-md color-sec-black font-bold m-b-40">
            ashreya Learning App
          </center>
          <img alt="LearningApp1" src={require("../../assets/About/LearningApp.jpg")} style={{ width: "100%", height: "100%" }} loading="lazy"/>
          <div className="m-t-10 mob-desc-font color-light-black" style={{ lineHeight: 1.6 }}>{about_3}</div>
          <div className="mob-desc-font color-light-black" style={{ lineHeight: 1.6 }}>{about_4}</div>
          <div className="m-t-10 mob-desc-font color-light-black" style={{ lineHeight: 1.6 }}>{about_5}</div>
        </div>
        <div className="connecting-parent">
          <div className="innovative-tech-child1">
            <div className="text-md color-sec-black font-bold ">
              Connecting Education and Community
            </div>
            <div className="m-t-30 text-sm color-light-black" style={{ textAlign: "justify", lineHeight: 1.6 }}>{about_6}</div>
          </div>
          <div className="innovative-tech-child2">
            <Lottie className='gif-style' options={defaultOptions(connectingEdu)} isClickToPauseDisabled={true} height={300} width={300} />
          </div>
        </div>
        <div className="mob-connecting-parent m-t-30">
          <center className="text-md color-sec-black font-bold m-b-20">
            Connecting Education and Community
          </center>
          <Lottie className='gif-style' options={defaultOptions(connectingEdu)} isClickToPauseDisabled={true} height={230} width={230} />
          <div className="m-t-10 mob-desc-font color-light-black" style={{ textAlign: "justify", lineHeight: 1.6 }}>{about_6}</div>
        </div>
        <div className="about-scholarship-parent">
          <div className="about-scholarship-child1">
            <Lottie className='gif-style' options={defaultOptions(scholarship)} isClickToPauseDisabled={true} height={300} width={300} />
          </div>
          <div className="about-scholarship-child2 text-align-right-div">
            <div className="text-md color-sec-black font-bold">
              ashreya Scholarship Program
            </div>
            <div className="m-t-30 text-sm color-light-black" style={{ lineHeight: 1.6 }}>{about_7}</div>
          </div>
        </div>
        <div className="mob-about-scholarship-parent m-t-30">
          <div className="text-md color-sec-black font-bold m-b-10">
            ashreya Scholarship Program
          </div>
          <Lottie className='gif-style' options={defaultOptions(scholarship)} isClickToPauseDisabled={true} height={230} width={230} />
          <div className="m-t-10 text-sm color-light-black" style={{ lineHeight: 1.6 }}>{about_7}</div>
        </div>
        <div className="values-parent">
          <div className="text-40 color-sec-black text-center font-bold mobile-font" >
            Our Values
          </div>
          <div className="values-child1">
            <ValueCard key={0} data={valueCardData[0]} />
            <ValueCard key={1} data={valueCardData[1]} />
            <ValueCard key={2} data={valueCardData[2]} />
          </div>
          <center>
            <div className="values-child2">
              <ValueCard key={3} data={valueCardData[3]} />
              <ValueCard key={4} data={valueCardData[4]} />
            </div>
          </center>
        </div>
        <div className="mob-values-parent">
          <div className="color-sec-black text-center font-bold mobile-font" >
            Our Values
          </div>
          <div className="m-t-30 m-b-30">
            <Carousel
              responsive={responsiveValues}
              arrows={false}
              autoPlay={true}
              renderButtonGroupOutside={true}
              customButtonGroup={<ButtonGroupValues />}>
              <ValueCardMob key={0} data={valueCardData[0]} />
              <ValueCardMob key={1} data={valueCardData[1]} />
              <ValueCardMob key={2} data={valueCardData[2]} />
              <ValueCardMob key={3} data={valueCardData[3]} />
              <ValueCardMob key={4} data={valueCardData[4]} />
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  const { subDomainHovered } = state.ashreya;
  return {
    subDomainHovered
  }
};
const mapDispatchToProps = (dispatch) => ({
  setSubDomainHovered: (val) => dispatch(setSubDomainHovered(val))
});
export default connect(mapStateToProps, mapDispatchToProps)(About);
// export default About;
