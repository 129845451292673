import React from 'react'
import '../styles.css'

const FaqData = [
    {
        title: "What is the ashreya Programme?",
        description: "The ashreya Programme is your gateway to success in medical and engineering education, offering personalized coaching and tech-enhanced learning.",
        cardColor: "#EAE8FF",
        width: "58.5%"
    },
    {
        title: "Who is eligible for the ashreya Scholarship?",
        description: "Exceptional students from ashreya and top JEE/NEET performers can win full tuition fee coverage.",
        cardColor: "#FEFFE3",
        width: "40%"
    },
    {
        title: "Can I apply for the ashreya Scholarship without joining the ashreya Programme?",
        description: "Yes! High achievers in JEE and NEET can apply for the ashreya Scholarship, no programme membership required.",
        cardColor: "#FFEED5",
        width: "49.5%"
    },
    {
        title: "What is ART (ashreya Ranker's Test)?",
        description: "ART is the ultimate test series for JEE/NEET aspirants, mirroring real exams.",
        cardColor: "#E3F8FD",
        width: "28%"
    },
    {
        title: "Can non-ashreya students take the ART test series?",
        description: "Absolutely! ART is for all JEE/NEET aspirants, ashreya or not.",
        cardColor: "#FFE0E0",
        width: "28%"
    },
    {
        title: "What is the Academy Programme?",
        description: "The Academy Programme is designed to assist Class X graduates in their transition to prepare for board exams and engineering/medical entrance exams.",
        cardColor: "#FFD9F2",
        width: "49%"
    },
    {
        title: "Are the Tuitions Programmes only for ashreya students?",
        description: "While Tuitions Programmes are part of the ashreya initiative, they are open to students beyond the ashreya Programme, offering academic support to a wider range of students.",
        cardColor: "#EBFFE4",
        width: "49%"
    },
]
const FaqCard = ({ data, mob }) => {
    return (
        <div className='ban-coaching-zoom' style={{ backgroundColor: data?.cardColor, height: "fit-content", width: mob ? "100%" : data?.width, boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", padding: 20, borderRadius: 8, marginTop: mob ? "20px" : "" }}>
            <div className='color-primary font-bold text-sm mob-title-font' style={{ lineHeight: 1.6 }}>{data?.title}</div>
            <div className='color-light-black text-sm m-t-10 mob-desc-font' style={{ lineHeight: 1.6 }}>{data?.description}</div>
        </div>
    )
}

function FAQSection() {
    return (
        <div>
            <div className='p-h-120 faqs-web-parent'>
                <div className='r-sb'>
                    <FaqCard data={FaqData[0]} />
                    <FaqCard data={FaqData[1]} />
                </div>
                <div className='r-sb m-t-20'>
                    <FaqCard data={FaqData[2]} />
                    {/* <FaqCard data={FaqData[3]} />
          <FaqCard data={FaqData[4]} /> */}
                    <FaqCard data={FaqData[6]} />
                </div>
            </div>
            <div className='p-h-20 faqs-mob-parent'>
                <FaqCard data={FaqData[0]} mob={true} />
                <FaqCard data={FaqData[1]} mob={true} />
                <FaqCard data={FaqData[2]} mob={true} />
                {/* <FaqCard data={FaqData[3]} mob={true} />
        <FaqCard data={FaqData[4]} mob={true} /> */}
                {/* <FaqCard data={FaqData[5]} mob={true} /> */}
                <FaqCard data={FaqData[6]} mob={true} />
            </div>
        </div>
    )
}

export default FAQSection