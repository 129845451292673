import React from 'react'
import '../styles.css'

function AspiringSection() {
  return (
    <div className='m-t-70 p-h-120 aspiring-parent'>
    <div className="c-cb font-bold aspiring-child1">
      <div className='c-c-c'>
        <div className='text-35 color-dark-black aspiring-mobile-font'>ASPIRING</div>
        <div className='text-35 color-dark-black aspiring-mobile-font m-t-2'>EDUCATION,</div>
        <div className='text-35 color-dark-black aspiring-mobile-font m-t-2'>INSPIRING LIVES</div>
      </div>
      <div className='c-c-c m-t-20'>
        <div className='text-sm color-dark-black'>Dream, Explore, Discover.</div>
        <center className='text-sm color-dark-black'>Nurturing young minds to unlock their absolute best.</center>
      </div>
    </div>
    <div className='r-c-c aspiring-child2'>
      <div className='geeks'>
        <img alt="aspiringImg" className='aspiring-img-width' src={require("../../../assets/AshreyaHome/aspiringImg.jpg")} style={{ borderRadius: 16 }} loading="lazy" />
      </div>
    </div>
  </div>
  )
}

export default AspiringSection