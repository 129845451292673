import React from 'react';
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers";
import thunk from "redux-thunk";
import './App.css';
import './design/color.css';
import './design/typography.css';
import './design/utils.css';
// import "antd/dist/antd.css";
import "react-multi-carousel/lib/styles.css";
import 'react-phone-number-input/style.css'
import Root from "./root";
import ReactGA from 'react-ga';
const ReactPixel = require('react-facebook-pixel');

export const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
);

class App extends React.Component {
  componentDidMount() {
    ReactGA.initialize('G-KQ7MTL0SY1');
    ReactGA.pageview(window.location.pathname + window.location.search)
    document.title = "ashreya";
    const razorpayScript = document.createElement("script");
    razorpayScript.src = "https://checkout.razorpay.com/v1/checkout.js";
    document.body.appendChild(razorpayScript);
    ReactPixel.default.init('1162281664583949');
  }

  render() {
    return (
      <Provider store={store}>
        <Root />
      </Provider>
    );
  }
}

export default App;
