import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setSideBar } from "../../action/ashreya";

const CustomSideBar = ({ setSideBar }) => {
  const [isTutorialMenuVisible, setIsTutorialMenuVisible] = useState(false);
  const [isAcademyMenuVisible, setIsAcademyMenuVisible] = useState(false);
  const [isCoachingMenuVisible, setIsCoachingMenuVisible] = useState(false);
  const [isTutorialResVisible, setTutorialsResVisible] = useState(false);
  const [isAcademyResVisible, setAcademyResVisible] = useState(false);
  const [isAcademyCourseVisible, setAcademyCourseVisible,] = useState(false);
  const [isAcademyTestVisible, setAcademyTestVisible] = useState(false);
  const [isCoachingResVisible, setCoachingResVisible] = useState(false);
  const [isCoachingCourseVisible, setCoachingCourseVisible] = useState(false);
  const [isCoachingTestVisible, setCoachingTestVisible] = useState(false);
  const [isCoachingMedVisible, setCoachingMedVisible] = useState(false);
  const [isCoachingEnggVisible, setCoachingEnggVisible] = useState(false);
  useEffect(() => {
    setIsTutorialMenuVisible(false);
    setIsAcademyMenuVisible(false);
    setIsCoachingMenuVisible(false);
    setTutorialsResVisible(false);
    setAcademyResVisible(false);
    setAcademyCourseVisible(false);
    setCoachingResVisible(false);
    setCoachingCourseVisible(false);
    setAcademyTestVisible(false);
    setCoachingTestVisible(false);
    setCoachingMedVisible(false);
    setCoachingEnggVisible(false)
  }, [])
  const menuStyle = {
    backgroundColor: '#DAC5EF', marginTop: 16, borderRadius: 5, padding: 10, textAlign: "left", height: "fit-content"
  }
  return (
    <div >
      <div className="r-sb-c p-h-20" style={{ height: "50px", width: "100%", backgroundColor: "#623695", }}>
        <Link to='/'>
          <div
            className="r-ac"
            style={{ width: "35%" }}
          >
            <img
              src={require("../../assets/ashreya-white-logo.svg").default}
              alt="ashreya"
              style={{ width: 150 }}
              loading="lazy"
            />
          </div>
        </Link>
        <div
          className='r-c-c'
          onClick={() => {
            setSideBar(false);
            setIsTutorialMenuVisible(false);
            setIsAcademyMenuVisible(false);
            setIsCoachingMenuVisible(false);
            setTutorialsResVisible(false);
            setAcademyResVisible(false);
            setAcademyCourseVisible(false);
            setCoachingResVisible(false);
            setCoachingCourseVisible(false);
            setAcademyTestVisible(false);
            setCoachingTestVisible(false);
            setCoachingMedVisible(false);
            setCoachingEnggVisible(false)
          }}
        >
          <img src={require("../../assets/TopBar/hamburger.svg").default} alt="hamburger" loading="lazy"/>
        </div>
      </div>
      <div className="p-h-15" style={{ height: "95vh", width: "100%", overflow: "auto", backgroundColor: "#ECE0F8" }}>
        <Link to="/">
          <div className="text-md color-primary m-b-10" style={menuStyle} onClick={() => setSideBar(false)}>
            HOME
          </div>
        </Link>
        <div style={menuStyle} >
          <div className="text-md color-primary m-b-5 r-flex-start" onClick={() => { setIsTutorialMenuVisible(!isTutorialMenuVisible); setIsAcademyMenuVisible(false); setIsCoachingMenuVisible(false) }}>
            TUTORIALS<img alt="downArrow" src={require("../../assets/AshreyaHome/primaryDownArrow.svg").default} style={{ marginLeft: 10, width: 25, height: 25 }} loading="lazy"/>
          </div>
          {isTutorialMenuVisible ?
            <div className='c-jc' style={{ marginLeft: 50 }}>
              <div className={`text-sm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://tutorials.ashreya.com/') }}>HOME</div>
              {/* <div className={`text-sm color-primary cursor-pointer m-t-10 r-flex-start`} onClick={() => { setTutorialsResVisible(!isTutorialResVisible) }}>RESOURCES <img alt="downArrow" src={require("../../assets/AshreyaHome/primaryDownArrow.svg").default} style={{ marginLeft: 10, width: 20, height: 20 }} loading="lazy"/></div>
              {isTutorialResVisible ?
                <div className='c-jc' style={{ marginLeft: 50 }}>
                  <div className={`text-xsm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://tutorials.ashreya.com/resources/cbse') }}>CBSE</div>
                  <div className={`text-xsm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://tutorials.ashreya.com/resources/seba') }}>ASSEB</div>
                  <div className={`text-xsm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://tutorials.ashreya.com/resources/announcements') }}>Announcements</div>
                </div>
                : <></>} */}
              {/* <div className={`text-sm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://tutorials.ashreya.com/facilities') }}>FACILITIES</div> */}
              <div className={`text-sm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://tutorials.ashreya.com/about') }}>ABOUT</div>
              <div className={`text-sm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://tutorials.ashreya.com/contact') }}>CONTACT</div>
            </div>
            : <></>}
        </div>
        {/* <div style={menuStyle} >
          <div className="text-md color-primary m-b-5 r-flex-start" onClick={() => { setIsAcademyMenuVisible(!isAcademyMenuVisible); setIsTutorialMenuVisible(false); setIsCoachingMenuVisible(false) }}>
            ACADEMY<img alt="downArrow" src={require("../../assets/AshreyaHome/primaryDownArrow.svg").default} style={{ marginLeft: 10, width: 25, height: 25 }} loading="lazy"/>
          </div>
          {isAcademyMenuVisible ?
            <div className='c-jc' style={{ marginLeft: 50 }}>
              <div className={`text-sm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://academy.ashreya.com/') }}>HOME</div>
              <div className={`text-sm color-primary cursor-pointer m-t-10 r-flex-start`} onClick={() => { setAcademyCourseVisible(!isAcademyCourseVisible) }}>COURSES <img alt="downArrow" src={require("../../assets/AshreyaHome/primaryDownArrow.svg").default} style={{ marginLeft: 10, width: 20, height: 20 }} loading="lazy"/></div>
              {isAcademyCourseVisible ?
                <div className='c-jc' style={{ marginLeft: 50 }}>
                  <div className={`text-xsm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://academy.ashreya.com/courses/medical') }}>Medical</div>
                  <div className={`text-xsm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://academy.ashreya.com/courses/engineering') }}>Engineering</div>
                </div>
                : <></>}
              <div className={`text-sm color-primary cursor-pointer m-t-10 r-flex-start`} onClick={() => { setAcademyResVisible(!isAcademyResVisible) }}>RESOURCES <img alt="downArrow" src={require("../../assets/AshreyaHome/primaryDownArrow.svg").default} style={{ marginLeft: 10, width: 20, height: 20 }} loading="lazy"/></div>
              {isAcademyResVisible ?
                <div className='c-jc' style={{ marginLeft: 50 }}>
                  <div className={`text-xsm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://academy.ashreya.com/resources/ncert') }}>NCERT</div>
                  <div className={`text-xsm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://academy.ashreya.com/resources/ahsec') }}>AHSEC</div>
                  <div className={`text-xsm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://academy.ashreya.com/resources/competative-exams') }}>Competative Exams</div>
                  <div className={`text-xsm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://academy.ashreya.com/resources/announcements') }}>Announcements</div>
                </div>
                : <></>}
              <div className={`text-sm color-primary cursor-pointer m-t-10 r-flex-start`} onClick={() => { window.location.assign('https://academy.ashreya.com/tests/asat') }}>ASAT Junior </div>
              {isAcademyTestVisible ?
                <div className='c-jc' style={{ marginLeft: 50 }}>
                  <div className={`text-xsm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://academy.ashreya.com/tests/art') }}>ART</div>
                  <div className={`text-xsm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://academy.ashreya.com/tests/asat') }}>ASAT</div>
                </div>
                : <></>}
              <div className={`text-sm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://academy.ashreya.com/facilities') }}>FACILITIES</div>
              <div className={`text-sm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://academy.ashreya.com/contact') }}>CONTACT US</div>
            </div>
            : <></>}
        </div> */}
        <div style={menuStyle} >
          <div className="text-md color-primary m-b-5 r-flex-start" onClick={() => { setIsCoachingMenuVisible(!isCoachingMenuVisible); setIsAcademyMenuVisible(false); setIsTutorialMenuVisible(false) }}>
            COACHING<img alt="downArrow" src={require("../../assets/AshreyaHome/primaryDownArrow.svg").default} style={{ marginLeft: 10, width: 25, height: 25 }} loading="lazy"/>
          </div>
          {isCoachingMenuVisible ?
            <div className='c-jc' style={{ marginLeft: 50 }}>
              <div className={`text-sm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://coaching.ashreya.com/') }}>HOME</div>
              <div className={`text-sm color-primary cursor-pointer m-t-10 r-flex-start`} onClick={() => { /* setCoachingCourseVisible(!isCoachingCourseVisible) */ window.location.assign('https://ashreya.aulas.in/') }}>COURSES {/* <img alt="downArrow" src={require("../../assets/AshreyaHome/primaryDownArrow.svg").default} style={{ marginLeft: 10, width: 20, height: 20 }} loading="lazy"/> */}</div>
              {/* {isCoachingCourseVisible ?
                <div className='c-jc' style={{ marginLeft: 50 }}>
                  <div className="display-flex" onClick={() => { setCoachingMedVisible(!isCoachingMedVisible) }}>
                    <div className={`text-xsm color-primary cursor-pointer m-t-10`} >Medical</div>
                    <div className="m-t-12"><img alt="downArrow" src={require("../../assets/AshreyaHome/primaryDownArrow.svg").default} style={{ marginLeft: 10, width: 18, height: 18, }} loading="lazy"/></div>
                  </div>
                  {isCoachingMedVisible ?
                    <div className='c-jc' style={{ marginLeft: 50 }}>
                      <div className={`text-xsm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://coaching.ashreya.com/medical-target-batch') }}>Target NEET 2025</div>
                      <div className={`text-xsm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://coaching.ashreya.com/neet-study-from-home') }}>Target NEET 2025 [Study from home]</div>
                    </div>
                    :
                    <></>
                  }
                  <div className="display-flex" onClick={() => { setCoachingEnggVisible(!isCoachingEnggVisible) }}>
                    <div className={`text-xsm color-primary cursor-pointer m-t-10`} >Engineering</div>
                    <div className="m-t-12"><img alt="downArrow" src={require("../../assets/AshreyaHome/primaryDownArrow.svg").default} style={{ marginLeft: 10, width: 18, height: 18, }} loading="lazy"/></div>
                  </div>
                  {isCoachingEnggVisible ?
                    <div className='c-jc' style={{ marginLeft: 50 }}>
                      <div className={`text-xsm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://coaching.ashreya.com/engineering-target-batch') }}>Target JEE</div>
                      <div className={`text-xsm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://coaching.ashreya.com/jee-study-from-home') }}>Target JEE 2025 [Study from home]</div>
                    </div>
                    :
                    <></>
                  }
                </div>
                : <></>} */}
              {/* <div className={`text-sm color-primary cursor-pointer m-t-10 r-flex-start`} onClick={() => { setCoachingResVisible(!isCoachingResVisible) }}>RESOURCES <img alt="downArrow" src={require("../../assets/AshreyaHome/primaryDownArrow.svg").default} style={{ marginLeft: 10, width: 20, height: 20 }} loading="lazy"/></div>
              {isCoachingResVisible ?
                <div className='c-jc' style={{ marginLeft: 50 }}>
                  <div className={`text-xsm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://coaching.ashreya.com/resources/ncert') }}>NCERT</div>
                  <div className={`text-xsm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://coaching.ashreya.com/resources/ahsec') }}>AHSEC</div>
                  <div className={`text-xsm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://coaching.ashreya.com/resources/competative-exams') }}>Competative Exams</div>
                  <div className={`text-xsm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://coaching.ashreya.com/resources/announcements') }}>Announcements</div>
                </div>
                : <></>} */}
              {/* <div className={`text-sm color-primary cursor-pointer m-t-10 r-flex-start`} onClick={() => { window.location.assign('https://coaching.ashreya.com/tests/asat') }}>ASAT Senior </div> */}
              {/* {isCoachingTestVisible ?
                <div className='c-jc' style={{ marginLeft: 50 }}>
                  <div className={`text-xsm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://coaching.ashreya.com/tests/art') }}>ART</div>
                  <div className={`text-xsm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://coaching.ashreya.com/tests/asat') }}>ASAT</div>
                </div>
                : <></>} */}
              <div className={`text-sm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://coaching.ashreya.com/facilities') }}>FACILITIES</div>
              <div className={`text-sm color-primary cursor-pointer m-t-10`} onClick={() => { window.location.assign('https://coaching.ashreya.com/contact') }}>CONTACT</div>
            </div>
            : <></>}
        </div>
      </div>
    </div >
  )
};

const mapStateToProps = (state) => {
  const { sideBar } = state.ashreya;
  return { sideBar };
};

const mapDispatchToProps = (dispatch) => ({
  setSideBar: val => dispatch(setSideBar(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomSideBar)