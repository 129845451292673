import React from 'react'
import '../styles.css'

function SubDomainCards({ text1, text2, index }) {
    return (
        <div
            className='subDomainCards c-c-c cursor-pointer' style={{ backgroundColor: index == 1 ? "#BBD3E9" : index == 2 ? "#F8E3CB" : "#BAD8C8", borderColor: index == 1 ? "#BBD3E9" : index == 2 ? "#F8E3CB" : "#BAD8C8" }}
            onClick={() => {
          /* if (index == 1) {
            window.location.assign('https://tutorials.ashreya.com/')
          } else */ if (index == 2) {
                    window.location.assign('https://academy.ashreya.com/')
                } else if (index == 3) {
                    window.location.assign('https://coaching.ashreya.com/')
                }
            }}>
            <div className='text-md color-sec-primary font-bold m-b-20'>{text1}</div>
            <center className='text-xsm color-sec-black'>{text2}</center>
        </div>
    )
}

export default SubDomainCards