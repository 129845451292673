import React from 'react'
import { WebBanner1Card } from './webShiningStarsBanner'

function MobShiningStarsBanner() {
  return (
    <div className='p-h-20'>
        <div className='font-bold text-24 color-primary m-t-40'>Meet our shining stars</div>
        <div className='text-sm color-light-black m-t-10' style={{ lineHeight: 1.6 }}>ashreya has strived to produce outstanding results over the years and has achieved the same with the hard work of it’s mentors and tireless efforts of the hard working students.</div>
        <div className='text-sm color-light-black m-t-20' style={{ lineHeight: 1.6 }}>The only need to achieve the dreams of our students was the right mentorship.</div>
        <div className='text-sm color-light-black m-t-20' style={{ lineHeight: 1.6 }}>Join ashreya and let us help you achieve your dreams.</div>
        <div className='r-sb m-t-10 m-b-20' style={{ width: "100%", alignItems: "center" }}>
          <WebBanner1Card
            image={require("../../../assets/AshreyaHome/shiningStar1.png")}
            name={"BITOPAN KALITA"}
            desc={"AIIMS GUWAHATI"}
            exam={"(NEET)"}
          />
          <WebBanner1Card
            image={require("../../../assets/AshreyaHome/shiningStar2.png")}
            name={"AMLAN JYOTI SARMA"}
            desc={"IIT MADRAS"}
            exam={"(JEE Adv)"}
          />
        </div>
      </div>
  )
}

export default MobShiningStarsBanner