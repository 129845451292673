import React, { useRef, useState, useEffect } from 'react';
import './styles.css';
import { Font17, Heading } from '../../../components/Typography';
import { Carousel } from 'antd';
import laptopScreen1 from "../../../assets/NewAshreyaHome/laptopScreen1.png";
import laptopScreen2 from "../../../assets/NewAshreyaHome/laptopScreen2.png";
import laptopScreen3 from "../../../assets/NewAshreyaHome/laptopScreen3.png";
import laptopScreen4 from "../../../assets/NewAshreyaHome/laptopScreen4.png";
import laptopScreen5 from "../../../assets/NewAshreyaHome/laptopScreen5.png";
import laptopScreen6 from "../../../assets/NewAshreyaHome/laptopScreen6.png";
import laptopScreen7 from "../../../assets/NewAshreyaHome/laptopScreen7.png";
import laptopScreen8 from "../../../assets/NewAshreyaHome/laptopScreen8.png";
import mobileScreen1 from "../../../assets/NewAshreyaHome/mobileScreen1.png";
import mobileScreen2 from "../../../assets/NewAshreyaHome/mobileScreen2.png";
import mobileScreen3 from "../../../assets/NewAshreyaHome/mobileScreen3.png";
import mobileScreen4 from "../../../assets/NewAshreyaHome/mobileScreen4.png";
import mobileScreen5 from "../../../assets/NewAshreyaHome/mobileScreen5.png";
import mobileScreen6 from "../../../assets/NewAshreyaHome/mobileScreen6.png";
import mobileScreen7 from "../../../assets/NewAshreyaHome/mobileScreen7.png";
import mobileScreen8 from "../../../assets/NewAshreyaHome/mobileScreen8.png";

const LaptopCarousel = ({ currentSlide, setCurrentSlide }) => {
    const screenshots = [
        laptopScreen1,
        laptopScreen2,
        laptopScreen3,
        laptopScreen4,
        laptopScreen5,
        laptopScreen6,
        laptopScreen7,
        laptopScreen8,
    ];

    return (
        <div className="laptop-container">
            <div className="screen">
                <Carousel
                    autoplay
                    dots={false}
                    arrows={false}
                    autoplaySpeed={3000}
                    beforeChange={(from, to) => setCurrentSlide(to)}
                    initialSlide={currentSlide}
                >
                    {screenshots.map((src, index) => (
                        <div key={index}>
                            <img src={src} alt={`screenshot-${index}`} className="screenshot" />
                        </div>
                    ))}
                </Carousel>
            </div>
        </div>
    );
};

const MobileCarousel = ({ currentSlide, setCurrentSlide }) => {
    const mobileScreenshots = [
        mobileScreen1,
        mobileScreen2,
        mobileScreen3,
        mobileScreen4,
        mobileScreen5,
        mobileScreen6,
        mobileScreen7,
        mobileScreen8,
    ];

    return (
        <div className="mobile-container">
            <div className="mobile-screen">
                <Carousel
                    autoplay
                    dots={false}
                    arrows={false}
                    autoplaySpeed={3000}
                    beforeChange={(from, to) => setCurrentSlide(to)}
                    initialSlide={currentSlide}
                >
                    {mobileScreenshots.map((src, index) => (
                        <div key={index}>
                            <img src={src} alt={`mobile-screenshot-${index}`} className="mobile-screenshot" />
                        </div>
                    ))}
                </Carousel>
            </div>
        </div>
    );
};

function AshreyaApp() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    setCurrentSlide(0);
                }
            },
            { threshold: 0.5 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <div className="experience-ashreya-app-container responsive-padding" ref={sectionRef}>
            <div className="experience-ashreya-app-card">
                <Heading bold lineHeight={24}>
                    Experience the learning on ashreya app.
                </Heading>
                <div className="ashreya-app-desc">
                    <Font17 lineHeight={32}>
                        Download the app from App Store/Play Store or visit{' '}
                        <a
                            href="https://ashreya.aulas.in"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {'  '} ashreya.aulas.in
                        </a>{' '}
                        and start learning today.
                    </Font17>
                </div>
                <div className="ashreya-app-content-parent">
                    <div className="c-cb ashreya-app-content-child1">
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <a
                                href="https://play.google.com/store/apps/details?id=com.ashreya.aulas&hl=en-IN"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={require("../../../assets/Footer/googlePlay.svg").default}
                                    alt="googlePlay"
                                    loading="lazy"
                                    className="playStore-image"
                                />
                            </a>
                            <img
                                loading="lazy"
                                alt="googlestoreQr"
                                className="qrcode-image"
                                src={require("../../../assets/Footer/googlestoreQr.png")}
                                style={{ border: 'none', borderRadius: 8 }}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <a
                                href="https://apps.apple.com/us/app/aulas/id1564724183?platform=iphone"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={require("../../../assets/Footer/appStore.svg").default}
                                    alt="appStore"
                                    loading="lazy"
                                    className="playStore-image"
                                />
                            </a>
                            <img
                                alt="appstoreQr"
                                loading="lazy"
                                className="qrcode-image"
                                src={require("../../../assets/Footer/appstoreQr.png")}
                                style={{ border: 'none', borderRadius: 8 }}
                            />
                        </div>
                    </div>
                    <div className="laptop-preview-margin" style={{ position: 'relative' }}>
                        <LaptopCarousel currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} />
                        <MobileCarousel currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} />
                    </div>
                </div>
                <div class="highlighted-text">
                    Key highlights:
                    <span class="live-classes"> live classes,</span>
                    <span class="recorded-videos"> recorded videos,</span>
                    <span class="notes-practice"> notes and practice papers,</span>
                    <span class="online-exams"> online exams.</span>
                </div>
            </div>
        </div>
    );
}

export default AshreyaApp;
