import React, { useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';
import Banner from './components/banner';
import ExploreCourses from './components/exploreCourses';
import AshreyaApp from './components/ashreyaApp';
import MoreAboutAshreya from './components/moreAboutAshreya';
import Mentorship from './components/mentorship';
import ShiningStars from './components/shiningStars';
import FAQ from './components/faq';
import Blogs from './components/blogs';
import FloatingBtn from './components/floatingBtn';
import Testimonials from './components/testimonials';
import '../NewAshreyaHome/components/styles.css';

function NewAshreyaHome() {
    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, []);

    const sections = [
        { Component: Banner, key: 'banner' },
        { Component: ExploreCourses, key: 'exploreCourses' },
        { Component: AshreyaApp, key: 'ashreyaApp' },
        // { Component: MoreAboutAshreya, key: 'moreAboutAshreya' },
        { Component: Mentorship, key: 'mentorship' },
        { Component: ShiningStars, key: 'shiningStars' },
        { Component: FAQ, key: 'faq' },
        { Component: Blogs, key: 'blogs' },
        { Component: Testimonials, key: 'testimonials' }
    ];

    return (
        <div className="container">
            <div className="content">
                {sections.map(({ Component, key }) => (
                    <AnimatedSection key={key}>
                        <Component />
                    </AnimatedSection>
                ))}
            </div>
            <FloatingBtn />
        </div>
    );
}

function AnimatedSection({ children }) {
    const ref = useRef(null);
    const controls = useAnimation();
    const mounted = useRef(false); // Track component mount state

    useEffect(() => {
        mounted.current = true; // Set to true when the component mounts

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && mounted.current) {
                    controls.start('visible');
                } else if (mounted.current) {
                    controls.start('hidden');
                }
            },
            { threshold: 0.1 }
        );

        const currentRef = ref.current;
        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
            mounted.current = false; // Set to false when the component unmounts
        };
    }, [controls]);

    return (
        <motion.div
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0, transition: { duration: 0.8 } }
            }}
        >
            {children}
        </motion.div>
    );
}

export default NewAshreyaHome;
