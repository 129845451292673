import { Button, Modal } from 'antd'
import React from 'react'
const NeetIcon = require("../../assets/AshreyaHome/schoolIntCard1Img.png")
const JeeIcon = require("../../assets/AshreyaHome/schoolIntCard2Img.png")

function CardDetailsModal({ isDetailsModalVisible, setIsDetailsModalVisible, cardIndex, setIsShareModalVisible, setShareUrl }) {
    const handleCancel = () => {
        setIsDetailsModalVisible(false)
    }
    return (
        <Modal
            centered
            visible={isDetailsModalVisible}
            onOk={handleCancel}
            onCancel={handleCancel}
            header={null}
            footer={null}
        >
            <center className='r-c-c'>
                <img alt="course-icon" src={cardIndex == 0 ? NeetIcon : JeeIcon} style={{ height: 60, width: 60, marginLeft: -20 }} loading="lazy"/>
                <center className='text-20 font-bold m-t-10 color-light-black'>{cardIndex == 0 ? "Boards + NEET" : "Boards + JEE"}</center>
            </center>
            <div className='m-t-20 m-b-20' style={{ width: "100%" }}>
                <div className='text-xsm color-light-black m-b-10'>Course Duration: <span className='font-bold'>2 years</span></div>
                <div className='text-xsm color-light-black m-b-10'>Integrated Curriculum: <span className='font-bold'>Boards (CBSE/AHSEC) + Entrances ({cardIndex == 0 ? "NEET" : "JEE"}/OLYMPIADS)</span></div>
                <div className='text-xsm color-light-black m-b-10'>Course Begins on: </div>
                <div className='text-xsm color-light-black m-b-10'>Daily Practice Papers </div>
                <div className='text-xsm color-light-black m-b-10'>Weekly Tests according to {cardIndex === 0 ? "NEET" : "JEE"} pattern</div>
            </div>
            <div className='r-sb p-h-30'>
                <div className='r-c-c m-r-30'
                    onClick={(e) => {
                        e.stopPropagation();
                        if (cardIndex == 0) {
                            setShareUrl("https://academy.ashreya.com/courses/medical")
                        } else if (cardIndex == 1) {
                            setShareUrl("https://academy.ashreya.com/courses/engineering")
                        }
                        setIsShareModalVisible(true);
                    }}
                >
                    <img alt="share-icon" src={require("../../assets/AshreyaHome/share-grey.png")} style={{ width: 15, height: 15 }} loading="lazy"/><div className='font-bold color-primary m-l-10'>SHARE</div>
                </div>
                <div className='color-primary font-bold'
                    onClick={() => {
                        if (cardIndex == 0) {
                            window.location.assign("https://academy.ashreya.com/courses/medical")
                        } else if (cardIndex == 1) {
                            window.location.assign("https://academy.ashreya.com/courses/engineering")
                        }
                    }}
                >VIEW DETAILS</div>
            </div>
        </Modal>
    )
}

export default CardDetailsModal