import React from 'react'
import '../styles.css'
import Lottie from 'react-lottie';
import lottie1 from '../../../assets/AshreyaHome/lottie1.json';
import lottie2 from '../../../assets/AshreyaHome/lottie2.json';
import lottie3 from '../../../assets/AshreyaHome/lottie3.json';
import lottie4 from '../../../assets/AshreyaHome/lottie4.json';
import lottie5 from '../../../assets/AshreyaHome/lottie5.json';
import lottie6 from '../../../assets/AshreyaHome/lottie6.json';

export const WhyAshreyaData = [
    {
      title: "Highly Experienced IITian Mentorship",
      icon: require("../../../assets/AshreyaHome/whyAshreyaIcon1.svg").default,
      text: "Only the most experienced and skilled IITians from the region are handpicked for the job.",
      lottie: lottie1
    },
    {
      title: "State-of-the-art Infrastructure",
      icon: require("../../../assets/AshreyaHome/whyAshreyaIcon2.svg").default,
      text: "Centralised AC campus, Smart Classrooms, Sophisticated Laboratories, High-Tech Support.",
      lottie: lottie2
    },
    {
      title: "Small Batch Advantage",
      icon: require("../../../assets/AshreyaHome/whyAshreyaIcon3.svg").default,
      text: "Focused learning in small groups ensures personalized guidance for every student.",
      lottie: lottie3
    },
    {
      title: "Instant Doubt Resolution",
      icon: require("../../../assets/AshreyaHome/whyAshreyaIcon4.svg").default,
      text: "Mentors are available 24x7 for on-demand instant doubt resolution. ",
      lottie: lottie4
    },
    {
      title: "Progress Tracking & Partnering with parents",
      icon: require("../../../assets/AshreyaHome/whyAshreyaIcon5.svg").default,
      text: "Pace of student growth monitored through regular assessments. Attendance & Grade Cards regularly shared with parents.",
      lottie: lottie5
    },
    {
      title: "Online/Offline on demand",
      icon: require("../../../assets/AshreyaHome/whyAshreyaIcon6.svg").default,
      text: "Equipped with multiple (offline/online/hybrid) modes of instruction, content-sharing, assessments & evaluation through ashreya App.",
      lottie: lottie6
    },
  ];

function WhyAshreyaCard({ key, data }) {
    let defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: data.lottie,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      };
  return (
    <div key={key} className='whyAshreyaCard zoom'>
          <div className="c-c-c">
            <img alt="whyUs" className='why-ashreya-icon' src={data.icon} loading="lazy" />
            <div className='why-ashreya-lottie'><Lottie options={defaultOptions} isClickToPauseDisabled={true} width={70} height={70} /></div>
            <div className="grey-line"></div>
            <center className='text-20 color-sec-primary mob-title-font' style={{ width: "80%", lineHeight: 1.5 }}>{data.title}</center>
            <center className='text-sm m-t-10 mob-desc-font' style={{ lineHeight: 1.6, color: "#5B5462", }}>{data.text}</center>
          </div>
        </div>
  )
}

export default WhyAshreyaCard