import React, { useRef, useState, useEffect } from 'react';
import './styles.css';
import { Font15, Font17, Font19, Heading } from '../../../components/Typography';
import { motion } from 'framer-motion';

function ShiningStars() {
    const [inView, setInView] = useState(false);
    const shiningStarsRef = useRef(null);

    const shiningStarsData = [
        {
            img: require("../../../assets/NewAshreyaHome/Bitopan.svg").default,
            name: "Bitopan Kalita",
            institute: "AIIMS Guwahati",
            exam: "(NEET)"
        },
        {
            img: require("../../../assets/NewAshreyaHome/Amlan.svg").default,
            name: "Amlan Jyoti Sarma",
            institute: "IIT Madras",
            exam: "(JEE Advanced)"
        },
    ];

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setInView(true);
                    } else {
                        setInView(false);
                    }
                });
            },
            { threshold: 0.5 }
        );

        if (shiningStarsRef.current) {
            observer.observe(shiningStarsRef.current);
        }

        return () => {
            if (shiningStarsRef.current) {
                observer.unobserve(shiningStarsRef.current);
            }
        };
    }, []);

    const ShiningStarsCard = ({ item }) => {
        return (
            <div className='c-c-c'>
                <motion.div
                    initial={{ scale: 0.5, opacity: 0.5 }}
                    animate={inView ? { scale: 1, opacity: 1 } : { scale: 0.5, opacity: 0.5 }}
                    transition={{ duration: 0.5, ease: "easeInOut" }}
                >
                    <img
                        src={item?.img}
                        alt="shining-stars"
                        className='shining-stars-img'
                        style={{ width: "100%", height: "auto", marginBottom: 20 }}
                    />
                </motion.div>
                <div className='shining-stars-info'>
                    <Font19 bold color='#191919'>{item?.name}</Font19>
                    <Font19 bold color='#664BA1'>{item?.institute}</Font19>
                    <Font15 color='#5B5462'>{item?.exam}</Font15>
                </div>
            </div>
        );
    };

    return (
        <div className='shining-stars-container responsive-padding' ref={shiningStarsRef}>
            <div className='shining-stars-content-child1'>
                {shiningStarsData?.map((item) => (
                    <ShiningStarsCard key={item.name} item={item} />
                ))}
            </div>
            <div className='shining-stars-content-child2'>
                <Heading bold margin={"0px 0px 30px 0px"}>Meet our shining stars</Heading>
                <center className='shining-star-sub-heading'>
                    <div>
                        <Font17 lineHeight={30}>Dreaming of reaching the heights that Amlan and Bitopan have? </Font17>
                    </div>
                    <div>
                        <Font17 lineHeight={30}>With our curated mentorship and your  dedication, we will write your success story!</Font17>
                    </div>
                </center>
            </div>
        </div>
    );
}

export default ShiningStars;
