import React from 'react'
import "../styles.css"

export const WebBanner1Card = ({ image, name, desc, exam }) => {
    return (
        <div>
            <div className='c-c-c' style={{ width: "fit-content" }}>
                <img src={image} alt='person-icon' className='banner1-shining-stars-size' loading="lazy" />
                <center className='text-20 color-light-black font-bold m-t-10 ban-media-font'>{name}</center>
                <center className='text-20 font-bold m-t-10 color-primary ban-media-font'>{desc}</center>
                <center className='text-20 color-light-black font-bold m-t-10 ban-media-font'>{exam}</center>
            </div>
        </div>
    )
}

function WebShiningStarsBanner() {
    return (
        <div className='r-sb m-t-20 ashreya-banner-wrapper' style={{ cursor: "default" }}>
            <div style={{ width: "60%" }}>
                <div className='font-bold text-xlg color-primary m-t-20'>Meet our shining stars</div>
                <div className='text-20 color-light-black m-t-30' style={{ lineHeight: 1.6 }}>ashreya has strived to produce outstanding results over the years and has achieved the same with the hard work of it’s mentors and tireless efforts of the hard working students.</div>
                <div className='text-20 color-light-black m-t-50' style={{ lineHeight: 1.6 }}>The only need to achieve the dreams of our students was the right mentorship.</div>
                <div className='text-20 color-light-black m-t-50' style={{ lineHeight: 1.6 }}>Join ashreya and let us help you achieve your dreams.</div>
            </div>
            <div className='r-sb m-t-10' style={{ width: "36%", alignItems: "center" }}>
                <WebBanner1Card
                    image={require("../../../assets/AshreyaHome/shiningStar1.png")}
                    name={"BITOPAN KALITA"}
                    desc={"AIIMS GUWAHATI"}
                    exam={"(NEET)"}
                />
                <WebBanner1Card
                    image={require("../../../assets/AshreyaHome/shiningStar2.png")}
                    name={"AMLAN JYOTI SARMA"}
                    desc={"IIT MADRAS"}
                    exam={"(JEE Adv)"}
                />
            </div>
        </div>
    )
}

export default WebShiningStarsBanner